import { html, LitElement } from 'lit-element';
import { dispatchEvent } from '@fil-global/gds-components/scripts/util/util';

import baseStyles from '../base/base.styles';
import componentStyles from './fil-pagination.styles';

export class thisComponent extends LitElement {
  static get properties() {
    return {
      items: { type: Number },
      currentPage: { type: Number },
      dropdown: { type: Object },
      previous: { type: Object },
      hyphen: { type: String },
      next: { type: Object },
      pages: { type: Number },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
    this.previous = {
      label: 'Previous',
    };
    this.next = {
      label: 'Next',
    };
    this.hyphen = 'of';
    this.dropdown = {};
    this.itemsPerPage = 1;
    this.pages = this.pages || this.getPages();
  }

  getPages() {
    const pages = Math.ceil(this.items / this.itemsPerPage);
    return pages;
  }

  handleChange(value, eventType = 'onchange change.gds.pagination') {
    this.currentPage = +value;
    dispatchEvent(this, eventType, {
      value: {
        active: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      },
    });
  }

  nextPage() {
    const newPage = this.currentPage + 1 > this.pages ? this.pages : this.currentPage + 1;
    this.handleChange(newPage);
  }

  previousPage() {
    const newPage = this.currentPage - 1 < 1 ? 1 : this.currentPage - 1;
    this.handleChange(newPage);
  }

  firstUpdated() {
    this.handleChange(1, 'onready ready.gds.pagination');
  }

  pageChangeHandler(e) {
    let value = +e.target.value;
    if (value > this.pages) {
      value = this.pages;
    } else if (value < 1) {
      value = 1;
    }
    e.target.value = value;
    this.handleChange(value);
  }

  updated() {
    setTimeout(() => {
      const select = this.dropdown.options
        ? this.shadowRoot.querySelector('fil-select').shadowRoot.querySelector('select')
        : null;
      if (select) {
        this.itemsPerPage = select.value;
      } else this.itemsPerPage = 1;
      this.pages = this.getPages();
      this.handleChange(this.currentPage);
    }, 1);
  }

  render() {
    const { label: previousText } = this.previous;
    const { label: nextText } = this.next;
    return html`<nav
      class="fil-pagination-wrapper"
      role="navigation"
      aria-label="Pagination Navigation"
    >
      ${this.dropdown && this.dropdown.options && this.dropdown.options.length
        ? html`<div class="fil-pagination__dropdown">
            <span>${this.dropdown.label}</span>
            <fil-select
              value=${this.dropdown.value}
              options=${JSON.stringify(this.dropdown.options)}
              @onchange=${e => {
                this.itemsPerPage = +e.detail.value;
                this.pages = this.getPages();
                this.currentPage = 1;
                e.stopPropagation();
                this.handleChange(this.currentPage);
              }}
            ></fil-select>
          </div>`
        : ''}
      <div class="fil-pagination__buttons">
        <fil-button
          kind="tertiary"
          size="standard"
          label=${previousText}
          iconPosition="left"
          iconSize="20"
          icon="custom-arrow-left"
          @click=${e => {
            this.previousPage(e);
          }}
          aria-label="Goto Previous Page"
          ?disabled=${this.currentPage === 1}
        ></fil-button>
        <fil-button
          kind="tertiary"
          size="standard"
          iconPosition="left"
          iconSize="20"
          icon="custom-arrow-left"
          class="fil-pagination__buttons-mobile"
          aria-label="Goto Next Page"
          @click=${e => {
            this.previousPage(e);
          }}
          ?disabled=${this.currentPage === 1}
        ></fil-button>
        <span class="fil-pagination__buttons-input"
          ><fil-text-input
            type="number"
            min="1"
            max="${this.pages}"
            @keyup=${e => {
              if (e.keyCode === 13) this.pageChangeHandler(e);
            }}
            aria-current="${this.currentPage}"
            @blur=${e => {
              this.pageChangeHandler(e);
            }}
            value=${this.currentPage}
          ></fil-text-input>
          ${this.hyphen} ${Math.ceil(this.pages)}</span
        >
        <fil-button
          kind="tertiary"
          size="standard"
          label=${nextText}
          iconPosition="right"
          iconSize="20"
          icon="custom-arrow-right"
          @click=${e => {
            this.nextPage(e);
          }}
          ?disabled=${this.currentPage === this.getPages()}
        ></fil-button>
        <fil-button
          kind="tertiary"
          size="standard"
          iconPosition="right"
          iconSize="20"
          icon="custom-arrow-right"
          class="fil-pagination__buttons-mobile"
          @click=${e => {
            this.nextPage(e);
          }}
          ?disabled=${this.currentPage === this.itemsPerPage * this.items}
        ></fil-button>
      </div>
    </nav>`;
  }
}

customElements.define('fil-pagination', thisComponent);
