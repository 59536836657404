import { LitElement, html } from 'lit-element';

class AccordionComponent extends LitElement {
  static get properties() {
    return {
      panels: { type: Array },
      multiPanel: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.panels = [];
    this.multiPanel = false;
    this.initialized = false;
  }

  shouldUpdate(changedProperties) {
    if (!this.initialized) {
      this.panels = Array.from(this.children);

      // the event should come from accordion-item.
      this.addEventListener('click.gds.accordion', event => {
        event.preventDefault();
        if (event.detail.innerAction) {
          this.handleClick(event);
        }
      });

      this.addEventListener('keyup.gds.accordion', event => {
        event.preventDefault();
        if (event.detail.innerAction) {
          this.handleKeyup(event);
        }
      });
      this.initialized = true;
    }

    return changedProperties;
  }

  updatePanels(target) {
    /* eslint-disable-next-line */
    this.panels.map(panel => {
      if (!this.multiPanel && panel.hasAttribute('expanded') && panel !== target) {
        panel.removeAttribute('expanded');
        panel.removeAttribute('aria-expanded');
      }

      if (panel === target) {
        if (panel.hasAttribute('expanded')) {
          panel.removeAttribute('expanded');
          panel.removeAttribute('aria-expanded');
        } else {
          panel.setAttribute('expanded', 'true');
          panel.setAttribute('aria-expanded', 'true');
          panel.focus();
        }
      }
    });

    return this.panels;
  }

  setNextPanel(target) {
    if (target !== this.panels[this.panels.length - 1]) {
      return target.nextElementSibling;
    }
    return this.panels[0];
  }

  setPreviousPanel(target) {
    if (target !== this.panels[0]) {
      return target.previousElementSibling;
    }
    return this.panels[this.panels.length - 1];
  }

  handleKeyup(event) {
    let { target } = event;
    switch (event.detail.key) {
      case 'Tab':
        if (event.shiftKey) {
          target = this.setPreviousPanel(target);
        } else {
          target = this.setNextPanel(target);
        }
        break;
      case 'Down':
      case 'ArrowDown':
        target = this.setNextPanel(target);
        break;
      case 'Up':
      case 'ArrowUp':
        target = this.setPreviousPanel(target);
        break;
      case 'Enter':
      case 'Space':
        this.updatePanels(target);
        break;
      default:
        break;
    }

    target.focus();
  }

  handleClick(event) {
    const { target } = event;
    this.updatePanels(target);
  }

  // eslint-disable-next-line
  render() {
    return html`<div class="fil-accordion"><slot></slot></div>`;
  }
}

customElements.define('fil-accordion', AccordionComponent);
