/* global Foundation */
import { TaggingEventTrigger } from '_fil/fil-global-frontend-gds3/adobe_track';
import * as CONSTANTS from './fund-search-consts';
import {
  formatFundHTML,
  getFundName,
  matchKeywordInString,
  replaceSpecialChars,
  formatKeyWord
} from './fund-search-helper';

export class FundSearchView {
  constructor(rootEl) {
    this.$root = $(rootEl || CONSTANTS.ROOT_SELECTOR);
    if (this.$root.length) {
      this.$input = this.$root.find(CONSTANTS.INPUT_SELECTOR);
      this.$butAllFund = this.$root.find(CONSTANTS.VIEW_ALL_BUTTON_SELECTOR);
      this.$inputIcon = this.$root.find(CONSTANTS.INPUT_ICON_SELECTOR);
      this.$dropDownItemContainer = this.$root.find(CONSTANTS.DROPDOWN_ITEM_CONTAINER_SELECTOR);
      this.$dropDownItemInnerContainer = this.$root.find(
        CONSTANTS.DROPDOWN_ITEM_INNER_CONTAINER_SELECTOR
      );
      this.$dropDownStickyItem = this.$root.find(CONSTANTS.DROPDOWN_STICKY_ITEM_SELECTOR);
      this.$dropDownStickyLinkItem = this.$root.find(CONSTANTS.DROPDOWN_STICKY_ITEM_LINK_SELECTOR);
      this.fundListAPI = window.fundListAPIURL;
      this.tagHandler = new TaggingEventTrigger(CONSTANTS.TAG_COMPONENT_KEY);
      this.bindEvents();
      this.get_search_tokenizers().then(data => {
        this.tokenizers_data = data;
      });
      setTimeout(() => {
        this.initSearch();
      }, 0);
    }
  }

  initSearch() {
    const keyword = this._getKeyword();
    const hasSearchResults =
      this.$dropDownItemInnerContainer.find(CONSTANTS.DROPDOWN_ITEM_SELECTOR).length > 0;
    if (this._canSearch(keyword) && !hasSearchResults) {
      this.startSearch(keyword, true);
    }
  }

  startSearch(keyword, hideSearchRes = false) {
    this.filterByKeywords(keyword).then(funds => {
      this.$dropDownItemInnerContainer.html('');
      this.updateDropdown(funds, hideSearchRes);
    });
  }

  _canSearch(keyword) {
    return keyword.length >= CONSTANTS.KEYWORD_LENGTH_MIN;
  }

  filterByKeywords(keyword) {
    return this.requestFundList().then(allFunds => {
      if (!allFunds || !allFunds.length) {
        return [];
      }

      const funds = this._filterKeyFromFundList(allFunds, keyword);
      return this._sortFunds(funds);
    });
  }

  /*
   * confirmed with Angelo, sort the fund table,
   * we need to sort from shareclassFacets.shareclassName.name whose languagetag is 'en-001', note:
   *   1. we need to remove special characters firstly
   *   2. we would better use `localeCompare` for further change to other languages.
   */
  _sortFunds(funds) {
    return funds.sort((fund1, fund2) => {
      const fundName1 = replaceSpecialChars(getFundName(fund1, CONSTANTS.FUND_NAME_LANGUAGETAG_EN));
      const fundName2 = replaceSpecialChars(getFundName(fund2, CONSTANTS.FUND_NAME_LANGUAGETAG_EN));
      return fundName1.localeCompare(fundName2);
    });
  }

  /*
   * confirmed with Fred, search the funds from the list, we need to match the search keyword with below fields:
   *   shareclassFacets.shareclassName[0].name - non-strict match, caseless
   *   shareclassFacets.shareclassName[1].name - non-strict match, caseless
   *   shareclassFacets.isin - non-strict match, caseless
   *   shareclassFacets.taCode - strict full match, caseless
   */
  _filterKeyFromFundList(allFunds, keyword) {
    if (!keyword) {
      return allFunds;
    }

    return allFunds.filter(fund => {
      let bingo = false;
      const facets = fund.shareclassFacets;
      const shareClassNames_props = facets && facets.shareclassName;

      if (shareClassNames_props && shareClassNames_props.length) {
        bingo = shareClassNames_props.some(prop =>
          matchKeywordInString(prop.name, keyword, false, true, this.tokenizers_data)
        );
      }

      if (facets.isin) {
        bingo =
          bingo || matchKeywordInString(facets.isin, keyword, false, true, this.tokenizers_data);
      }

      if (facets.taCode) {
        bingo = bingo || matchKeywordInString(facets.taCode, keyword, true);
      }
      return bingo;
    });
  }

  requestFundList() {
    const requestDfd = $.Deferred();
    if (this.cachedFundList) {
      requestDfd.resolve(this.cachedFundList);
    } else {
      if (this.fundListAPI) {
        $.ajax({
          type: 'GET',
          crossDomain: true,
          url: this.fundListAPI
        })
          .done(data => {
            this.cachedFundList = data;
            requestDfd.resolve(data);
          })
          .fail(() => {
            requestDfd.resolve([]);
          });
      }
    }
    return requestDfd.promise();
  }

  updateDropdown(funds, hideSearchRes) {
    if (funds) {
      if (funds.length) {
        this.buildDropdownListHTML(funds);
        this.updateFundCount(funds.length);
        this.$dropDownItemContainer.removeClass(CONSTANTS.DROPDOWN_ITEM_CONTAINER_EMPTY_CLASS);
        if (hideSearchRes) {
          this.$dropDownItemContainer.addClass('in-searching');
        } else {
          this.reCalculateHeight();
        }
      } else {
        this.$dropDownItemContainer.addClass(CONSTANTS.DROPDOWN_ITEM_CONTAINER_EMPTY_CLASS);
      }

      if (!hideSearchRes) {
        this.prepareDropDown();
      }
    }
  }

  updateFundCount(num) {
    this.$dropDownStickyLinkItem.html(() => {
      return this.$dropDownStickyLinkItem.text().replace(/\(\d+\)/, `(${num})`);
    });

    const keyword = encodeURIComponent(this._getKeyword());
    this.$dropDownStickyLinkItem.attr('href', (_, link) => {
      return link.replace(/=[^=]*$/, `=${keyword}`);
    });
  }

  _getKeyword() {
    return formatKeyWord(this.$input.val());
  }

  _isDifferentKey() {
    const keyword = this._getKeyword();
    if (this._lastTaggingKeyword == keyword) {
      return false;
    }
    this._lastTaggingKeyword = keyword;
    return keyword;
  }

  buildDropdownListHTML(funds) {
    const html = funds.map(fund => formatFundHTML(fund)).join('');
    this.$dropDownItemInnerContainer.html(html);
  }

  prepareDropDown() {
    this.$dropDownItemContainer.addClass('active');
  }

  reCalculateHeight() {
    this.visibleHeight = this.$dropDownStickyItem.outerHeight();
    $.each(
      this.$dropDownItemContainer
        .find(CONSTANTS.DROPDOWN_ITEM_SELECTOR)
        .slice(0, CONSTANTS.VISIBLE_DROPDOWN_NUM),
      (r, row) => {
        this.visibleHeight += $(row).outerHeight();
      }
    );
    this.$dropDownItemContainer.css('max-height', this.visibleHeight);
  }

  resetSearchForm() {
    this.$dropDownItemContainer.removeClass('active in-searching').css('max-height', '');
  }

  bindEvents() {
    this.$input.on(
      'keyup',
      Foundation.util.throttle(() => {
        const keyword = this._getKeyword();
        if (this._canSearch(keyword)) {
          this.startSearch(keyword);
        } else {
          this.resetSearchForm();
        }
      }, CONSTANTS.TYPE_DELAY)
    );

    this.$input.on(
      'keyup',
      Foundation.util.throttle(() => {
        const keyword = this._isDifferentKey();
        if (this._canSearch(keyword)) {
          this.tagHandler.tag(CONSTANTS.TAG_ETK_INPUT_BOX, { event_content: keyword });
        }
      }, CONSTANTS.TAG_INPUT_DEBOUNCE)
    );

    this.$input.on('focus', () => {
      if (this.$dropDownItemContainer.hasClass('in-searching')) {
        this.prepareDropDown();
        this.$dropDownItemContainer.removeClass('in-searching');
      }
    });

    this.$dropDownItemInnerContainer.on(
      'click touch',
      `${CONSTANTS.DROPDOWN_ITEM_SELECTOR} a`,
      event => {
        const $link = $(event.currentTarget);
        const isin = $link.attr('isin');
        const fundName = $link.text();
        this.tagHandler.tag(CONSTANTS.TAG_ETK_FUND_ITEM, { event_content: `${fundName}-${isin}` });
      }
    );

    this.$inputIcon.on('click touch', () => {
      if (this.$dropDownItemContainer.hasClass('in-searching')) {
        this.prepareDropDown();
        this.$dropDownItemContainer.removeClass('in-searching');
      }
    });

    this.$butAllFund.on('click touch', () => {
      this.tagHandler.tag(CONSTANTS.TAG_ETK_VIEW_ALL_FUNDS);
    });

    this.$dropDownStickyLinkItem.on('click touch', event => {
      const $link = $(event.currentTarget);
      const linkText = $link
        .text()
        .replace(/\(\d+\)/, '')
        .trim();
      this.tagHandler.tag(CONSTANTS.TAG_ETK_STICKY_LINK, { event_content: linkText });
    });

    $('body').on('click touch', event => {
      const keyword = this._getKeyword();
      const inputWrapperDOM = this.$input.parent(CONSTANTS.INPUT_WRAPPER_SELECTOR)[0];
      const clickInside =
        inputWrapperDOM.contains(event.target) ||
        this.$dropDownItemContainer[0].contains(event.target);
      const hasInSearchingClass = this.$dropDownItemContainer.hasClass('in-searching');
      if (!clickInside && keyword && !hasInSearchingClass) {
        this.resetSearchForm();
        this.$dropDownItemContainer.addClass('in-searching');
      }
    });
  }

  get_search_tokenizers() {
    const requestDfd = $.Deferred();
    $.ajax({
      url: CONSTANTS.FUND_SEARCH_TOKENIZERS_API_URL,
      type: 'GET'
    })
      .done(data => {
        const tokenizers_list = data.tokenizers.map(tokenizer => tokenizer.trim().split(/\s+/));
        requestDfd.resolve(tokenizers_list);
      })
      .fail(() => {
        requestDfd.resolve([]);
      });

    return requestDfd.promise();
  }
}
