import { html, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import uid from 'uid';
import { dispatchEvent } from '@fil-global/gds-components/scripts/util/util';

import baseStyles from '../base/base.styles';
import componentStyles from './fil-switch.styles';

class SwitchComponent extends LitElement {
  static get properties() {
    return {
      inversed: { type: Boolean },
      items: { type: Array },
      size: { type: String },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
    this.uid = uid();
    this.active = null;
  }

  handleChange() {
    const input = this.shadowRoot.querySelector('input:checked');
    if (!input) {
      return;
    }

    dispatchEvent(this, 'onchange change.gds.switch', {
      value: input.value,
      checked: input.checked, // todo: probability unneeded as it dispatches the 'checked' one only
    });
    this.active = input.value;
  }

  keyHandler(e) {
    if (e.keyCode === 32) {
      const input = e.target.parentNode.querySelector('input');
      input.checked = !input.checked;
      this.handleChange();
      e.preventDefault();
    }
  }

  render() {
    const icon = !!this.items[0].icon;
    const classes = {
      'fil-switch': true,
      'fil-switch--inversed': this.inversed,
      'fil-switch--small': this.size === 'small',
      'fil-switch--icon': icon,
    };

    return html`
      <div class="${classMap(classes)}">
        ${this.items.map(item => {
          const id = uid();
          return html`<div class="fil-switch__item">
            <input
              class="fil-switch__field"
              type="radio"
              name="${item.name}"
              value="${item.value}"
              ?checked=${!!item.checked}
              aria-checked=${!!item.checked}
              @change=${this.handleChange}
              id="${item.id || id}"
              role="switch"
            />
            <label
              class="fil-switch__label ${this.active === item.value ? 'fil-switch--active' : ''}"
              aria-label="${item.description || item.name}"
              for="${item.id || id}"
              id="label_${item.id || id}"
              tabindex="0"
              @keypress=${this.keyHandler}
            >
              ${item.icon ? html`<fil-icon size="20" icon="${item.icon}"></fil-icon>` : ''}
              ${item.label ? html`<span>${item.label}</span>` : null}</label
            >
          </div>`;
        })}
      </div>
    `;
  }
}

customElements.define('fil-switch', SwitchComponent);
