import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';

$(() => {
  if ($('.fil-show-hide-wrapper').length > 0) {
    $('.fil-show-hide-wrapper').each((_, el) => {
      const $hiddenWrapper = $('.fil-show-hide-wrapper-inner--hidden', el);
      const $togglerDOM = $hiddenWrapper.find('.toggler');
      const $togglerLabelDOM = $togglerDOM.find('.label');
      const $togglerIcon = $togglerDOM.find('fil-icon');
      const $hiddenInnerWrapper = $hiddenWrapper.find('.hidden-wrapper');
      const hideLabel = $togglerDOM.data('hide-label');
      const showLabel = $togglerLabelDOM.text();
      const canHide = hideLabel && hideLabel.length > 0;

      $togglerDOM.on('click', ()=>{
        if($hiddenInnerWrapper.is(':hidden')) {
          $hiddenInnerWrapper.removeClass('hide');
          if(canHide){
            $togglerLabelDOM.text(hideLabel);
            $togglerIcon.attr('icon', 'custom-chevron-up');
          }else{
            $togglerDOM.addClass('hide');
          }
        } else {
          $hiddenInnerWrapper.addClass('hide');
          $togglerLabelDOM.text(showLabel);
          $togglerIcon.attr('icon', 'custom-chevron-down');
        }
      });
    });
  }
});
