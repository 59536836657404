import { html, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';

import ifNonNull from '../globals/directives/isNotNull';
import { channel, textKind, truncateCharacters } from './util';
import baseStyles from '../base/base.styles';
import componentStyles from './fil-hero-home.styles';

class HeroBlockItem extends LitElement {
  static get properties() {
    return {
      title: { type: String },
      /**
       * image url
       */
      image: { type: String },
      horizontal: { type: Boolean },
      // link properties
      link: { type: Object },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  connectedCallback() {
    // for a11y
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'listitem');
    }
    super.connectedCallback();
  }

  handleClick() {
    const root = this.shadowRoot;
    const linkEle = root.querySelector('.fil-hero-block__header a');
    linkEle.click();
  }

  renderTitle() {
    if (!this.title) return null;
    const truncatedTitle = truncateCharacters(this.title, channel.BLOCK, textKind.TITLE);
    return html`<h4 class="fil-hero-block__header">
      <a href=${ifNonNull(this.link?.url)} target=${ifNonNull(this.link?.target)}
        >${truncatedTitle}</a
      >
    </h4>`;
  }

  render() {
    const cardImage = this.image;
    const classes = {
      'fil-hero-block': true,
      'fil-hero-block--horizontal': !!this.horizontal,
    };
    return html`<div class=${classMap(classes)} @click=${this.handleClick}>
      ${cardImage
        ? html`<div class="fil-hero-block__image"><img src=${cardImage} alt=${this.title} /></div>`
        : ''}
      <div class="fil-hero-block__body">
        ${this.renderTitle()}
        <div class="fil-hero-block__content"><slot></slot></div>
      </div>
    </div>`;
  }
}

customElements.define('fil-hero-block-item', HeroBlockItem);
