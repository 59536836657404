import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';

const DEFAULT_CHECKED_BUTTON_SIZE = 'standard';

class FILContentSwitcher {
  constructor(selector){
    this.$root= $(selector);
    this.$filteredGroups = this.$root.find('.fil-dropdown-filtered-content .fil-dropdown-options');
    this.$lastActiveGroup = this.$filteredGroups.filter('.active');
    this.$switcherDOM = this.$root.find('.fil-switcher-dom');
    this.bindEvents();
  }

  bindEvents(){
    const groups = this.$filteredGroups;
    const $switcherDOM = this.$switcherDOM;

    if(groups.length > 0 && $switcherDOM.length){
      $switcherDOM.on('onchange', (e) => {
        let currentValue = e.detail.value;
        if(this.$lastActiveGroup){
          this.$lastActiveGroup.removeClass('active');
        }
        this.$lastActiveGroup = groups.filter(`[data-option-value='${currentValue}']`).addClass('active');
      });
    }
  }
}

class FILSelectSwitcher extends FILContentSwitcher {
}

class FILCheckedButtonSwitcher extends FILContentSwitcher {
  constructor(selector) {
    super(selector);
    this.defaultSize = this.$switcherDOM.attr('size') || DEFAULT_CHECKED_BUTTON_SIZE;
    this.updateSwitcherSize();
  }

  updateSwitcherSize() {
    this.$switcherDOM.attr('size', ()=>{
      return MediaQuery.only('small') ? 'small': this.defaultSize;
    });
  }

  bindEvents() {
    super.bindEvents();
    $(window).on('changed.zf.mediaquery', () => {
      this.updateSwitcherSize();
    });
  }
}


$(() => {
  if ($('.fil-content-switcher').length > 0) {
    $('.fil-content-switcher').each((_, el) => {
      if ($(el).hasClass('fil-checked-buttons')) {
        new FILCheckedButtonSwitcher(el);
      } else {
        new FILSelectSwitcher(el);
      }
    });
  }
});
