export const TAGGING_EVENT_MAP = {
  'HERO-HOMEPAGE': {
    'MAIN_CTA': {
      'event_category': 'hero template',
      'event_action': 'banner click',
      'event_content': 'main cta'
    },
    'BOTTOM_CTA': {
      'event_category': 'hero template',
      'event_action': 'banner click',
      'event_content': 'cta_{}'
    }
  },
  'FUND_SEARCH': {
    'VIEW_ALL_FUNDS': {
      'event_category': 'fund search box',
      'event_action': 'banner click',
      'event_content': 'view all funds'
    },
    'SEARCH_BOX_KEYWORD': {
      'event_category': 'fund search box',
      'event_action': 'search',
      'event_content': '[input text]'
    },
    'FUND_ITEM': {
      'event_category': 'fund search box',
      'event_action': 'link click',
      'event_content': '[fund name]-[ISIN]'
    },
    'STICKY_LINK': {
      'event_category': 'fund search box',
      'event_action': 'link click',
      'event_content': '[link text]'
    }
  },
  'SOCIAL_SHARING': {
    'FACEBOOK': {
      'event_category': 'social share',
      'event_action': 'Share to facebook',
      'event_content': '{Page URL}'
    },
    'LINE': {
      'event_category': 'social share',
      'event_action': 'Share to line',
      'event_content': '{Page URL}'
    },
    'LINKEDIN': {
      'event_category': 'social share',
      'event_action': 'Share to linkedin',
      'event_content': '{Page URL}'
    },
    'EMAIL': {
      'event_category': 'social share',
      'event_action': 'Share to email',
      'event_content': '{Page URL}'
    },
    'DOWNLOAD': {
      'event_category': 'social share',
      'event_action': 'Save as PDF',
      'event_content': '{Page URL}'
    }
  }
};

export function triggerTaggingEvent({ event_category, event_action, event_content, type = 'event', action = 'global.interactionEvent' }) {
  const detail = {
    type: type,
    action: action,
    event_category: event_category,
    event_action: event_action,
    event_content: event_content
  };
  document.dispatchEvent(
    new CustomEvent('analytics', {
      detail: detail
    })
  );
  console.log(
    '%cISS / Analytics:',
    'font-weight:bold;color:green;font-size:14px;',
    detail
  );
}

/*
  eventTrackers = {trackerKey: 1}
  prevent request for multiple times.
  will reset after 50 millisecond.
*/
const EventTrackers = {
  _records: {},
  EXPIRED_TIME: 50,
  canSendOut(options) {
    const { event_category: category, event_action: action, event_content: content } = options;
    const eventKey = `${category}-${action}-${content}`;
    if (eventKey in this._records) {
      return false;
    } else {
      this._records[eventKey] = 1;
      return eventKey;
    }
  },

  //remove the event from tracker after 50 millisecond.
  release(eventKey) {
    setTimeout(() => {
      delete this._records[eventKey];
    }, this.EXPIRED_TIME);
  }
};

/*
* bindTaggingEventOnWebComponent('fil-hero-home', [
    {validator: ruleFunc, options: {}, get_options: function(){}}
  ])

  `get_options` and `options` can be provided only one.
*/
export function bindTaggingEventOnWebComponent(compSelector, maps) {
  $(compSelector).on('click.gds.button', evt => {
    const paths = evt.composed ? evt.composedPath() : evt.originalEvent.composedPath();
    for (let { validator, options, get_options } of maps) {
      const node = paths.find(node => validator && validator(node, paths));
      if (node) {
        if (!options && get_options) {
          options = get_options(node);
        }

        if (options) {
          const eventKey = EventTrackers.canSendOut(options);
          if (eventKey) {
            triggerTaggingEvent(options);
            EventTrackers.release(eventKey);
          }
          break;
        }
      }
    }
  });
}

export class TaggingEventTrigger {
  constructor(componentType) {
    componentType = componentType.toUpperCase();
    if (componentType in TAGGING_EVENT_MAP) {
      this.taggingOptions = TAGGING_EVENT_MAP[componentType];
    }
  }

  /*
  * parameters:
  * key: element key
  * otherOptions: options to override defaultOptions
  */
  tag(key, otherOptions = {}) {
    key = key.toUpperCase();
    if (key in this.taggingOptions) {
      const options = { ...this.taggingOptions[key], ...otherOptions };
      triggerTaggingEvent(options);
    }
  }
}

//triggerSocialSharingTaggingEvent('facebook')
export function triggerSocialSharingTaggingEvent(which) {
  const socialSharingOptions = TAGGING_EVENT_MAP['SOCIAL_SHARING'];
  which = which.toUpperCase();
  if (which in socialSharingOptions) {
    const options = socialSharingOptions[which];
    options['event_content'] = location.href;
    triggerTaggingEvent(options);
  }
}

//monitor gds button event, in order to send out analysis request about the download PDF.
$('body').on('click.gds.button', evt => {
  if (window.AppMeasurement) {
    const paths = evt.composed ? evt.composedPath() : evt.originalEvent.composedPath();
    //look for <a class='fil-button' tag
    const buttonObj = paths.find(node => node.tagName && node.tagName.toLowerCase() == 'a' && node.classList && node.classList.contains('fil-button'));
    const appM = window.AppMeasurement.getInstance(window.s.account || '');
    if (appM && buttonObj) {
      const linkUrl = buttonObj.href;
      const mediaTypes = appM.linkDownloadFileTypes || 'pdf';
      const pattern = new RegExp(`\\.(${mediaTypes.replace(/,/gi, '|')})$`, 'i');
      if (linkUrl && pattern.test(linkUrl)) {
        appM.linkObject = buttonObj;
        appM.linkURL = linkUrl;
        appM.linkType = 'd';
        appM.trackLink(buttonObj, 'd', false);
      }
    }
  }
});
