import { html, LitElement, css } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import {
  getDeviceType,
  SMALL,
  MEDIUM,
  detectIEEdge,
} from '@fil-global/gds-components/scripts/util/util';

import baseStyles from '../base/base.styles';
import componentStyles from './fil-button-group.styles';
import '../fil-button/fil-button.component';

export class ButtonGroupComponent extends LitElement {
  static get properties() {
    return {
      stacked: { type: Boolean },
      position: { type: String },
    };
  }

  static get styles() {
    return [
      baseStyles,
      componentStyles,
      css`
        ::slotted(fil-button) {
          width: var(--fil-button-width);
        }
      `,
    ];
  }

  constructor() {
    super();
    this.initialized = false;
    this.handleResize = this.handleResize.bind(this);
  }

  get isMobile() {
    const breakpoint = getDeviceType();
    return breakpoint === SMALL || breakpoint === MEDIUM;
  }

  get isIEbrowser() {
    const browser = detectIEEdge();
    if(browser && browser === 11){
      return true;
    }
    return false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.classes = {
      ['fil-button-group']: true,
      [`fil-button-group--stacked`]: this.stacked && !this.isIEbrowser,
      [`fil-button-group--stacked-ie`]: this.stacked && this.isIEbrowser,
    };
    window.addEventListener('resize', this.handleResize);
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.handleResize);
    super.disconnectedCallback();
  }

  handleResize() {
    if (this.isIEbrowser) {
      this.setButtonWidth();
    }
  }

  firstUpdated() {
    if (this.isIEbrowser) {
      this.slotButtons = Array.from(this.children);
      this.setButtonWidth();
    }
  }

  setButtonWidth() {
    const slotButtons = this.slotButtons;
    let maxLength = this.slotButtons[0].offsetWidth;
    // if container of button display none, the offsetWidth is 0, so give an min-width
    if(maxLength === 0){
      maxLength = 185;
    }
    // children render takes time, so should use asyn
    setTimeout(() => {
      if (!this.isMobile) {
        if(!this.maxLength){
        slotButtons.forEach((button) => {
          if (button.offsetWidth > maxLength) {
            maxLength = button.offsetWidth;
          }
        });
        }else{
          maxLength = this.maxLength
        }
        slotButtons.forEach((button) => {
          button.style.width =`${maxLength + 1}px`;
          button.style.marginRight = '20px';
          if(this.stacked){
            button.style.display = 'flex';
          }
        });
        this.maxLength = maxLength;
      } else {
        slotButtons.forEach((button) => {
          button.style.width = `100%`;
           button.style.marginRight = '0';
          if(this.stacked){
            button.style.display = null;
          }
        });
      }
    }, 100);
  }

  wrapperTemplate() {
    return html`<div
      class="fil-button-group-wrapper fil-button-group-wrapper--center"
    >
      ${this.buttonGroupTemplate()}
    </div>`;
  }

  buttonGroupTemplate() {
    return html`<div class=${classMap(this.classes)} role="group" aria-label="Button Group">
      <slot></slot>
    </div>`;
  }

  render() {
    if (this.position === 'center') {
      return this.wrapperTemplate();
    }
    return this.buttonGroupTemplate();
  }
}

customElements.define('fil-button-group', ButtonGroupComponent);
