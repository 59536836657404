const { watchListConfigKeys } = require('./watchListConfig');
const { SearchSiteConfigHandler } = require('./utils.js');
const Paginator = require('_fil/fil-global-frontend-gds3/paginator');
const { getLoginState } = require('_fil/fil-global-frontend-gds3/login');
import { getWatchlist, userWlistNewArray } from './watchlist_integration';
import {
  AUTOSCROLL_SPEED,
  DEFAULT_FILTER_ALL,
  DE_FUND_FACTSHEET_URL,
  DOCTYPE_BADGE_BGCOLORS,
  DE_FUND_TYPE,
  UK_FUND_TYPE,
  ARTICLE_TYPE
} from './constants';

/**
 * Search Results
 *
 * This class handles the retrieval, filtering, display and pagination of search
 * results from both the Fund Finder API and the CMS ElasticSearch.
 *
 */
export class SearchPaginator extends Paginator {
  constructor(rootEl, opts = {}) {
    super(rootEl, opts);

    this.allFunds = [];
    this.fundResults = [];
    this.cmsResults = [];
    this.dataSet = [];

    this.docType = DEFAULT_FILTER_ALL;
    this.lastSearch = undefined;
    this.searcher = opts.searcher;
    this.formHelper = opts.form;
    this.tabIndex = opts.tabIndex;
    this.$resultTemplate = this.$root.find('.search-result.search__template');
    this.$filTabs = this.$root.closest('fil-tabs');

    this.initConfig();

    if(this.isUKFundsWatchlistEnabled){
      getLoginState().then(loginresult => {
        this.isUserLoggedInForSearchBind = loginresult;
        this.rebuild();
      });

      getWatchlist(watchListConfigKeys.WatchlistAjaxURL).then(
        () => this.rebuild(),
        (err) => console.error(err)
      );
    }
  }

  initConfig(){
    this.configHandlerInstance = new SearchSiteConfigHandler();
    this.isUKFundsDealEnabled =
      this.configHandlerInstance.getForKey('UKFUNDS_DEAL_ENABLED').toLowerCase() === 'true';
    this.isUKFundsWatchlistEnabled =
      this.configHandlerInstance.getForKey('UKFUNDS_WATCHLIST_ENABLED').toLowerCase() === 'true';
    this.isUKHelpSupportTabEnabled =
      this.configHandlerInstance.getForKey('UK_HELP_SUPPORT_TAB_ENABLED').toLowerCase() === 'true';
    this.isUserLoggedInForSearchBind = false;

    this.appendHelpandSupportTab();
  }

  /**
   * Generate the full set of results by joining the fund and CMS results,
   * filtering by type if necessary. Funds have priority and display first.
   * @return {array} List of result objects\
   *
   */
  //TODO: GDS3 tabs component doesn't support dynamically add li item.
  //modify it to use backup.
  appendHelpandSupportTab() {
    const $HELP_AND_SUPPORT = `<li data-identity='helpsupport' class='tabs-title' id='help_and_support'>
                                <a href='javascript:void(0)' aria-selected='false' role='tab'>Help & support</a>
                            </li>`;
    if (this.isUKHelpSupportTabEnabled && $('#help_and_support').length < 1) {
      $('#search-result-tabs').append($HELP_AND_SUPPORT);
    }
  }

  rebuild(doctype, reset) {
    if(doctype){
      this.docType = doctype;
    }

    if(reset){
      this.resetPage();
      this.dataSet = [];
    }

    super.rebuild();
  }

  onStateChange() {
    this.formHelper.updatePagination(this.page, this.perPage);
  }

  getResults() {
    const { cmsResults=[], fundResults=[] } = this.searcher.searchData;
    if(this.dataSet.length == 0){
      let dataSet = [];
      switch (this.docType) {
        case DEFAULT_FILTER_ALL:
          dataSet = fundResults.concat(cmsResults);
          break;
        case 'fund':
          dataSet = fundResults;
          break;
        default:
          dataSet = cmsResults.filter(res => res.type === this.docType);
      }
      this.dataSet = dataSet;
    }

    return this.dataSet;
  }

  /**
    This is the return for the content that needs to be displayed in the search result template
  */
  buildExcerptFromUKFund(result) {
    const wishlist = userWlistNewArray();
    const isFundAddedToWatchList = wishlist.includes(result.isin);
    const watchlistFundAdded = isFundAddedToWatchList ? 'arrow-right-cta' : '';
    const watchlistLabel = isFundAddedToWatchList
      ? watchListConfigKeys.addedLabel
      : 'Add to watchlist';
    const watchlistHref = isFundAddedToWatchList
      ? watchListConfigKeys.addedLink
      : 'javascript:void(0)';

    const deal_url = `"javascript:openDealLayer( '${result.isin}','Global Search_Off Platform Fund Searched_No' )"`;
    const dealLink = this.isUKFundsDealEnabled
      ? `<a class="search-result__deal-link" href=${deal_url}>Deal</a>`
      : '';

    const watchlistLink =
      this.isUserLoggedInForSearchBind && this.isUKFundsWatchlistEnabled
        ? `<a
            class="search-result__watchlist-link linkWatchlist ${watchlistFundAdded}"
            fundName="${result.title}"
            href="${watchlistHref}"
            id="wlist${result.isin}"
            isin=${result.isin}
            sedol=${result.sedol}
          >${watchlistLabel}</a>`
        : '';

    return `
      <a class="search-result__isin-link" href="${result.url}">
        ${result.isin}
      </a>
      ${result.content}
      <p class="search-result__link-wrapper">
        ${dealLink}
        <a class="search-result__factsheet-link" href="${result.url}">View Factsheet</a>
        ${watchlistLink}
      </p>
    `;
  }

  /**
   * Retrieve the results for a particular page.
   *
   * @return {array} Array of results in a format understood by buildResult()
   */
  getResultsForPage(page) {
    const results = this.getResults();
    const start = page * this.perPage;
    const end = (page + 1) * this.perPage;
    return results.slice(start, end);
  }

  /**
   * Retrieve the total number of results
   *
   * @return {number} Total results
   */
  getTotalResults() {
    return this.getResults().length;
  }

  /**
   * Scroll smoothly to the top of the screen
   */
  onPageChange() {
    $('html, body').animate({ scrollTop: 0 }, AUTOSCROLL_SPEED);
  }

  buildTotalResultsOutput(total) {
    super.buildTotalResultsOutput(total);
    this.updateTabTitle(total);
  }

  updateTabTitle(total){
    const filTabsDOM = this.$filTabs[0];
    if(filTabsDOM && filTabsDOM.shadowRoot && this.tabIndex >= 0){
      const $tabLink = $(filTabsDOM.shadowRoot.querySelectorAll('.tabs > li.tabs-title a')[this.tabIndex]);
      $tabLink.html($tabLink.html().replace(/(\(\d*\))?$/,`(${total})`));
      $tabLink.css('width', '');
    }
  }

  /**
   * Build the DOM for a single result
   * @param {object} result Data for a single result
   * @return {DOM Element}  DOM element representing the result
   */
  buildResult(result) {
    // Clone and append template
    const el = this.$resultTemplate
      .clone()
      .removeClass('search__template')
      .addClass(`search-result--${result.type}`);

    let excerpt, title, url;
    switch (result.type) {
      case DE_FUND_TYPE:
        title = result.FN;
        url = DE_FUND_FACTSHEET_URL + result.ISIN;
        excerpt = `WKN: ${result.WKN}&nbsp;&nbsp;&nbsp;&nbsp;ISIN: ${result.ISIN}`;
        break;
      case UK_FUND_TYPE:
        title = result.title;
        url = result.url;
        excerpt = this.buildExcerptFromUKFund(result);
        break;
      default:
        excerpt = result.content;
        title = result.title;
        url = result.url;
        break;
    }

    el.find('.search-result__excerpt').html(excerpt);
    const docTypeLabel = this.formHelper.getDocTypeLabel(result.type) || result.type;
    el.find('.search-result__doctype').html(docTypeLabel).css('background-color', DOCTYPE_BADGE_BGCOLORS[result.type]);
    const el_link = el.find('.search-result__link')
      .attr('href', url)
      .html(title);

    if (/\.s3.+amazonaws\.com/.test(url) || /\/canonical\//.test(url)) {
      el_link.attr('target', '_blank');
    }

    if(result.type == ARTICLE_TYPE && result.publishDate) {
      el.find('.search-result__title').before(`<div class="search-result__date">${result.publishDate}</div>`);
    }

    return el;
  }
}
