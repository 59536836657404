import { LitElement, html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import uid from 'uid';
import { getDeviceType, SMALL } from '@fil-global/gds-components/scripts/util/util';

import dropdownStyles from '../fil-dropdown/fil-dropdown.styles';
import baseStyles from '../base/base.styles';
import { renderAuthorImage, SIZE, ALIGN } from './util';
import componentStyles from './fil-author.styles';
import '../fil-icon/fil-icon.component';

class AuthorComponent extends LitElement {
  static get properties() {
    return {
      /*
       * [{name: '', image: '', link: '', pureText: false, other: { description:''}],
       * other is object for extendable. pureText just for single author, it indicates no image and avatar.
       */
      authors: { type: Array }, //
      size: { type: String }, // "medium" || "small"
      align: { type: String }, // "left" || "center"
      multiAuthor: { type: Object }, // text of multiple author
      _templateChange: { type: String, attribute: false },
      _flatList: { type: Boolean, attribute: false },
    };
  }

  constructor() {
    super();
    this.uid = uid();
    this.size = SIZE.MEDIUM; // 'medium';
    this.align = ALIGN.LEFT; // 'left';
    this.multiAuthor = {
      name: 'Multiple Authors',
      other: { description: 'View authors' },
    };
    // more than 2 authors, whether has enough space? YES: just list, NO: show one dropdown
    this._templateChange = 'YES'; // "NO";
    this._flatList = true;
    this._boundListener = this.handleWindowClick.bind(this);
    this._handleResize = this.handleWindowResize.bind(this);
  }

  static get styles() {
    return [baseStyles, dropdownStyles, componentStyles];
  }

  static get isMobile() {
    const breakpoint = getDeviceType();
    return breakpoint === SMALL;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('click', this._boundListener);
    window.addEventListener('keypress', this._boundListener);
    window.addEventListener('resize', this._handleResize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('click', this._boundListener);
    window.removeEventListener('keypress', this._boundListener);
    window.removeEventListener('resize', this._handleResize);
  }

  handleWindowClick(event) {
    if (event.type === 'click' || event.key === 'Escape' || event.key === 'Esc') {
      this.toggleDropdown(true);
    }
  }

  handleWindowResize() {
    // TODO use underscore.js _debounce function to replace this.
    setTimeout(() => {
      if (!this.isMobile && this.authors.length > 1) {
        this.calculateAuthors();
      }
    }, 50);
  }

  authorsTemplate() {
    if (this.authors) {
      return html` ${this.authors.map(person => this.authorTemplate(person))} `;
    }
    return null;
  }

  authorTemplate(person) {
    const authorCls = {
      'fil-one-author': true,
      [`fil-one-author--small`]: this.size && this.size.toLowerCase() === SIZE.SMALL,
    };
    return html`
      <div class=${classMap(authorCls)}>
        ${renderAuthorImage(person)}
        <div class="fil-author__info">
          <h6>
            ${!person.multiple
              ? html`<a ?href="${person.link}" tabindex="0">${person.name}</a>`
              : html`<span>${person.name}</span>`}
          </h6>
          ${!person.multiple
            ? html`<span>${person.other.description}</span>${person.other.readingtime
                  ? html`<span
                      >${person.other.description ? html`&nbsp;&bull;&nbsp;` : null}${person.other
                        .readingtime}
                    </span>`
                  : null}`
            : html`<span
                class="fil-author__multiple-tip"
                @click="${this.iconToggleHandler}"
                role="button"
                tabindex="0"
              >
                ${person.other.description}
                <fil-icon
                  size="8"
                  icon="custom-chevron-down"
                  class="fil-author__dropdown-icon"
                ></fil-icon>
              </span>`}
        </div>
      </div>
    `;
  }

  multipleTemplate() {
    const { name, other } = this.multiAuthor;
    const author = {
      name,
      other,
      multiple: true,
    };
    return html`<div class="fil-dropdown-author">
      ${this.authorTemplate(author)}
      <ul class="fil-author-dropdown" role="list">
        ${this.authors.map(person => html`<li>${this.authorTemplate(person)}</li>`)}
      </ul>
    </div>`;
  }

  renderTemplate() {
    let num = this.authors ? this.authors.length : 0;
    if (!this.isMobile && num > 1 && this._templateChange === 'NO') {
      num = 99;
    }

    if (this.isMobile) {
      num = num > 1 ? 99 : num;
    }
    // store current author for calculate list width;
    this.number = num;
    const classes = {
      'fil-authors': true,
      [`fil-authors--center`]: this.align && this.align === ALIGN.CENTER,
      [`fil-authors--multiple`]: num !== 0 && num !== 1 && num !== 99,
    };

    switch (num) {
      case 0:
        return null;
      case 99:
        return html`<div class=${classMap(classes)}>${this.multipleTemplate()}</div>`;
      default:
        return html`<div class=${classMap(classes)}>${this.authorsTemplate()}</div>`;
    }
  }

  toggleDropdown(close) {
    const dropdown = this.shadowRoot.querySelector('.fil-author-dropdown');
    if (!dropdown) {
      return null;
    }
    const dropdownIcon = this.shadowRoot.querySelector('.fil-author__multiple-tip');

    if (close) {
      dropdownIcon.classList.remove('fil-author__multiple-tip--active');
      dropdown.classList.remove('fil-author-dropdown--active');
      dropdown.setAttribute('aria-hidden', true);
      return null;
    }

    if (dropdownIcon.classList.contains('fil-author__multiple-tip--active')) {
      dropdownIcon.classList.remove('fil-author__multiple-tip--active');
      dropdown.classList.remove('fil-author-dropdown--active');
      dropdown.setAttribute('aria-hidden', true);
    } else {
      dropdownIcon.classList.add('fil-author__multiple-tip--active');
      dropdown.classList.add('fil-author-dropdown--active');
      dropdown.setAttribute('aria-hidden', false);
    }
    return null;
  }

  iconToggleHandler(e) {
    e.stopPropagation();
    this.toggleDropdown();
  }

  authorsWidth() {
    const dropdown = this.shadowRoot.querySelector('.fil-authors');
    const authors = dropdown.querySelectorAll('.fil-one-author');
    // every multiple author has an 32px margin.
    let totalWidth = 32;
    authors.forEach(author => {
      totalWidth += author.offsetWidth + 32;
    });
    return totalWidth;
  }

  calculateAuthors() {
    const dropdown = this.shadowRoot.querySelector('.fil-authors');
    const authors = dropdown.querySelectorAll('.fil-one-author');
    const { totalWidth } = this;

    if (authors.length >= 2 && totalWidth > dropdown.offsetWidth) {
      this._templateChange = 'NO';
    } else {
      this._templateChange = 'YES';
    }
    // to trigger template re-render
    this._flatList = !this._flatList;
  }

  shouldUpdate(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'authors' && !this.isMobile) {
        this._templateChange = 'YES';
      }
    });
    return changedProperties;
  }

  render() {
    return this.renderTemplate();
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'authors' && !this.isMobile) {
        if (this.number !== 99) {
          // store flat list total width
          this.totalWidth = this.authorsWidth();
        }
        this.calculateAuthors();
      }
    });
  }
}

customElements.define('fil-author', AuthorComponent);
