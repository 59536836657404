import { html, LitElement } from 'lit-element';
import { styleMap } from 'lit-html/directives/style-map';
import { classMap } from 'lit-html/directives/class-map';
import { calculatePosition } from '@fil-global/gds-components/scripts/tooltip/index';

import '../fil-icon/fil-icon.component';
import '../fil-button/fil-button.component';
import baseStyles from '../base/base.styles';
import componentStyles from './fil-external-link.styles';
import linkStyles from '../fil-link/fil-link.styles';

class ExternalLinkComponent extends LitElement {
  static get properties() {
    return {
      label: { type: String },
      /**
       * @type {"top"|"bottom"}
       */
      position: { type: String },
      /**
       * @type {"right"|"left"|"center"}
       */
      alignment: { type: String },
      message: { type: String },
      /**
       * @type {"hover"|"click"}
       */
      style: { type: String, attribute: false },
      show: { type: Boolean, attribute: false },
      icon: { type: String },
      size: { type: String },
      url: { type: String },
      overlay: { type: Boolean },
      disabled: { type: Boolean },
    };
  }

  static get styles() {
    return [baseStyles, linkStyles, componentStyles];
  }

  constructor() {
    super();
    this.position = 'top';
    this.alignment = 'center';
    this.show = false;
    this.icon = 'expand-6';
    this.label = 'External URL';
    this.overlay = false;
    this.size = 'standard';
    this.disabled = false;
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('click', this.handleClickOutside);
  }

  disconnectedCallback() {
    window.removeEventListener('click', this.handleClickOutside);
    super.disconnectedCallback();
  }

  handleClickOutside(e) {
    if (
      !this.shadowRoot.querySelector('fil-icon').contains(e.target) &&
      !this.advanced
    ) {
      this.closeTooltip();
    }
  }

  handleMouseover(e) {
    e.stopPropagation();
  }

  handleMouseout(e) {
    e.stopPropagation();
  }

  handleClick(e) {
    if (this.overlay) {
      e.stopPropagation();
      if (!this.show) {
        // calculate tooltip position
        const messageContainer = this.shadowRoot.querySelector(
          '.fil-external-link-msg'
        );
        const calculateStyle = calculatePosition(e.target, messageContainer, {
          position: this.position,
          alignment: this.alignment,
          messageHeight: this.tipMessageHeight,
          messageWidth: this.tipMessageWidth,
        });
        this.style = `${calculateStyle} width: ${this.tipMessageWidth}px;`;

        setTimeout(() => (this.show = true), 200);
      } else {
        this.closeTooltip();
      }
    }
  }

  closeTooltip() {
    setTimeout(() => {
      this.show = false;
      this.style = 'display: none;';
    }, 200);
  }
  handleUrl(e) {
    window.open(this.url, '_blank');
    this.closeTooltip();
  }

  get messageTemplate() {
    return;
    const style = `${this.style};`;
    return html`
      <div
        class="fil-external-link-msg"
        style="${style}"
        role="tooltip"
        aria-hidden="${this.show ? true : false}"
      >
        <fil-icon
          size="20"
          class="fil-external-link-close"
          icon="close"
          @click="${this.handleClick}"
        ></fil-icon>
        <div class="fil-external-link-body">${this.message}</div>
        <fil-button
          class="fil-external-link-msg__button"
          label="Okay"
          kind="secondary"
          size="standard"
          @click="${this.handleUrl}"
        ></fil-button>

        <fil-button
          class="fil-external-link-msg__button"
          label="Cancel"
          kind="secondary"
          @click="${this.closeTooltip}"
        ></fil-button>
      </div>
    `;
  }

  render() {
    const classes = {
      'fil-external-link-shown': this.show ? true : false,
      'fil-external-link-small': this.size === 'small' ? true : false,
      'fil-external-link-wrapper': true,
    };
    return html`
      <div class=${classMap(classes)}>
        <a
          tabindex="0"
          aria-label="${this.message}"
          @click="${this.handleClick}"
          href="${this.url}"
          target="_blank"
          ?disabled="${this.disabled}"
          class="fil-external-link--anchor fil-link"
          title="${this.label}"
          >${this.label} <fil-icon icon="${this.icon}"></fil-icon
        ></a>
        ${this.overlay ? this.messageTemplate : ''}
      </div>
    `;
  }

  async firstUpdated() {
    if (!this.overlay) return;
    const messageContainer = this.shadowRoot.querySelector(
      '.fil-external-link-msg'
    );
    await this.updateComplete;
    this.tipMessageHeight = messageContainer.offsetHeight;
    this.tipMessageWidth = messageContainer.offsetWidth;
    this.style = 'display: none';
  }
}

customElements.define('fil-external-link', ExternalLinkComponent);
