/* eslint-disable no-nested-ternary */
import { html, LitElement } from 'lit-element';
import { ifDefined } from 'lit-html/directives/if-defined';
import { classMap } from 'lit-html/directives/class-map';

import ifNonNull from '../globals/directives/isNotNull';
import baseStyles from '../base/base.styles';
import componentStyles from './fil-standard-card.styles';
import '../fil-button/fil-button.component';

class StandardCardComponent extends LitElement {
  static get properties() {
    return {
      inversed: { type: Boolean },
      art: { type: String },
      ctaType: { type: String },
      image: { type: String },
      category: { type: String },
      categoryurl: { type: String },
      headline: { type: String },
      subtext: { type: String },
      icon: { type: String },
      ctaIcon: { type: String },
      cta: { type: String },
      ctaUrl: { type: String },
      ctaExtraProps: { type: Object },
      ctaIconSize: { type: Number },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
    this.variant = 'grey-50';
  }

  linkTemplate() {
    return html`
      ${this.categoryurl
        ? html`
            <div class="fil-card__category">
              <a href="${this.categoryurl}">${this.category}</a>
            </div>
          `
        : this.category
        ? html` <span class="fil-card__category">${this.category}</span> `
        : ''}
      ${this.ctaUrl
        ? html`<a href="${this.ctaUrl}" tabindex="-1" class="fil-card__headline"
            >${this.headline}</a
          >`
        : html`<span class="fil-card__headline">${this.headline}</span>`}
    `;
  }

  linkToCtaUrl() {
    if (this.ctaUrl) {
      window.location.href = this.ctaUrl;
    }
  }

  render() {
    const classes = {
      'fil-card': true,
      'fil-card--inversed': this.inversed,
    };
    return html`
      <div class="${classMap(classes)}">
        ${this.image && this.art === 'image'
          ? html`
              <div
                class="fil-card__image"
                style="background-image:url('${this.image}')"
                @click="${this.linkToCtaUrl}"
                tabindex="-1"
              ></div>
            `
          : ''}
        <div class="fil-card__content">
          ${this.icon && this.art === 'icon'
            ? html`
                <div class="fil-card__icon" style="background-image:url('${this.icon}')"></div>
              `
            : ''}
          ${this.subtext
            ? html` <h3 class="fil-card__title" tabindex="-1">${this.linkTemplate()}</h3> `
            : this.linkTemplate()}
          ${this.subtext
            ? html`
                <p class="fil-card__subtext" tabindex="-1" @click="${this.linkToCtaUrl}">
                  ${this.subtext}
                </p>
              `
            : ''}
        </div>
        <div class="fil-card__footer">
          ${this.ctaType
            ? html` <div class="fil-card__cta">
                <fil-button
                  kind="${this.ctaType}"
                  label=${this.cta}
                  url=${ifDefined(this.ctaUrl)}
                  size="standard"
                  iconSize="${this.ctaIconSize}"
                  icon="${this.ctaType === 'textlink' ? this.ctaIcon || 'custom-arrow-right' : ''}"
                  iconPosition="${this.ctaType === 'textlink' ? 'right' : ''}"
                  extraProps=${ifNonNull(this.ctaExtraProps)
                    ? JSON.stringify({ ...this.ctaExtraProps })
                    : null}
                ></fil-button>
              </div>`
            : ``}
        </div>
      </div>
    `;
  }
}

customElements.define('fil-standard-card', StandardCardComponent);
