const queryString = require('qs');
import {KEYPRESS_RETURN, DEFAULT_PAGE_FROM, DEFAULT_PER_PAGE} from './constants';

export class SpinnerHelper {
  constructor() {
    this.$spinnerContainer = $('#search-results__spinner-container');
  }

  on() {
    this.$spinnerContainer.show();
  }

  off() {
    this.$spinnerContainer.hide();
  }
}

export class TypeaheadHelper {
  constructor() {
    this.typeaHeadEnabled = $.fn.typeahead !== undefined;
  }

  setQuery(value) {
    if(value && this.$typeAhead) {
      this.$typeAhead.typeahead('val', value);
    }
  }

  initSuggester(formHelper, pageCategory) {
    const $form = formHelper.$form;
    const suggesterURL = $form.data('searchurl');
    const $typeAhead = this.$typeAhead = formHelper.getTypeAheadDOM();
    const $inputBox = formHelper.getSearchInput();

    if (this.typeaHeadEnabled) {
      $typeAhead
        .typeahead({
          hint: true,
          highlight: true,
          minLength: 1
        }, {
          limit: 99,
          async: true,
          source: (query, processSync, processAsync) => {
            return $.ajax({
              url: suggesterURL,
              type: 'GET',
              data: {
                query_text: query,
                pagecategory: pageCategory,
                is_ajax: true
              },
              success: function (data) {
                return processAsync(data);
              }
            });
          }
        })
        .on('typeahead:selected', () => {
          if (formHelper.canSearch()) {
            formHelper.search();
          }
        })
        .on('keyup', e => {
          if (e.keyCode == KEYPRESS_RETURN && formHelper.canSearch()) {
            formHelper.search();
          }
          formHelper.updateSubmitButtonState();
        })
        .on('search', e => {
          formHelper.updateSubmitButtonState();
        })
        .on('keypress', e => {
          let keyCode = e.keyCode || e.which;
          if (keyCode === KEYPRESS_RETURN) {
            e.preventDefault();
            $typeAhead.typeahead('close');
            return false;
          }
        });
    }
  }
}

export class SearchParamParser {
  constructor() {
    this._states = this.updateStates();

    $(window).on('popstate', () => {
      const states = this._states = this.updateStates();

      if(!$.isEmptyObject(states) && this._hashChangeEventHandler) {
        this._hashChangeEventHandler(states);
      }
    });
  }

  updateStates(){
    const initStates =  this.initStates();
    return $.extend({}, initStates, this.parseStatesFromLocation());
  }

  initStates(){
    return {
      'tab-index': 0,
      'page': DEFAULT_PAGE_FROM,
      'perPage': DEFAULT_PER_PAGE
    };
  }

  parseStatesFromLocation() {
    return queryString.parse(window.location.search, {
      ignoreQueryPrefix: true
    });
  }

  onHashChanged(callback) {
    this._hashChangeEventHandler = callback;
  }

  getStates() {
    return this._states;
  }

  update(key, value) {
    const states = this._states;
    let changed = false;

    if(typeof(key) == 'object'){
      for(const k in key){
        if(this.isChanged(k, key[k])){
        //if(!(k in states) || states[k] != key[k]){
          changed = true;
          states[k] = key[k];
        }
      }
    } else if(value != undefined) {
      if(this.isChanged(key, value)) {
      //if(!(key in states) || states[key] != value) {
        changed = true;
        states[key] = value;
      }
    }

    return changed;
  }

  isChanged(key, value) {
    return !(key in this._states) || this._states[key] != value;
  }

  updateP(key, value) {
    if(this.update(key, value)) {
      this._updateLocation(true);
    }
  }

  updateR(key, value) {
    if(this.update(key, value)) {
      this._updateLocation(false);
    }
  }

  _updateLocation(append) {
    const strStates = `?${queryString.stringify(this._states)}`;

    if(append){
      history.pushState({}, '', strStates);
    } else {
      history.replaceState({}, '', strStates);
    }
  }

  reset() {
    this._states = this.initStates();
  }
}
