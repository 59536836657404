export const SELECT_TYPE = {
  /**
   * single select
   */
  SINGLE: 'single',

  /**
   * multiple select
   */
  MULTIPLE: 'multiple',
};
