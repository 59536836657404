/* global Foundation */
const HeaderBase = require('./header-base');
import $ from 'jquery';
import { Foundation } from 'foundation-sites/js/foundation.core';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';

$(document).ready(function() {
  MediaQuery._init();
  let header = new HeaderBase(Foundation);
  header.init();
});
