import { html, LitElement } from 'lit-element';
import { styleMap } from 'lit-html/directives/style-map';

import ifNonNull from '../globals/directives/isNotNull';
import baseStyles from '../base/base.styles';
import componentStyles from './fil-text-media-grid.styles';
import '../fil-button/fil-button.component';
import '../fil-icon/fil-icon.component';

const position = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
};

const VIDEO_MAX_HEIGHT = 320;
const VIDEO_MIN_HEIGHT = 250;

class MediaGridComponent extends LitElement {
  static get properties() {
    return {
      /**
       * @type {"grey-50" | "white"}
       */
      variant: { type: String },
      header: { type: String },
      toggleContainer: { type: Boolean },
      /**
       * @type {"left"|"right"}
       */
      mediaAlign: { type: String },
      imageUrl: { type: String },
      videoUrl: { type: String },
      videoImageUrl: { type: String },
      ctaText: { type: String },
      /**
       * @type {"primary"|"secondary"|"tertiary"|"textlink"}
       */
      ctaKind: { type: String },
      ctaUrl: { type: String },
      ctaIcon: { type: String },
      ctaIconSize: { type: Number },
      ctaExtraProps: { type: Object },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
    this.variant = 'grey-50';
    this.toggleContainer = false;
  }

  ctaTemplate() {
    if (this.ctaKind) {
      return html`
        <p
          class="fil-media-grid__cta ${this.ctaKind === 'textlink'
            ? 'fil-media-grid__cta--link'
            : 'fil-media-grid__cta'}"
        >
          <fil-button
            kind="${this.ctaKind}"
            label="${this.ctaText}"
            url="${ifNonNull(this.ctaUrl)}"
            size="standard"
            icon="${this.ctaIcon}"
            iconPosition="right"
            iconSize="${this.ctaIconSize}"
            extraProps=${ifNonNull(this.ctaExtraProps)
              ? JSON.stringify(Object.assign({}, this.ctaExtraProps))
              : null}
          ></fil-button>
        </p>
      `;
    }
  }

  getMediaAlign() {
    let clsName = '';
    if (this.mediaAlign && this.mediaAlign.toUpperCase() === position.LEFT) {
      clsName = 'fil-media-grid--reverse';
      if (this.toggleContainer) {
        clsName += ' fil-media-grid--toggled';
      }
    }
    return clsName;
  }

  mediaTemplate() {
    if (this.imageUrl) {
      return html`<img src="${this.imageUrl}" alt="text media grid image" />`;
    } else if (this.videoUrl) {
      let styles = { backgroundImage: `url(${this.videoImageUrl})` };
      let isWebMedia = true;
      if (
        this.videoUrl.indexOf('video.fidelity.tv') >= 0 ||
        this.videoUrl.indexOf('www.youtube.com') >= 0
      ) {
        isWebMedia = false;
      }

      // calculate video height
      const sectionNodes = this.shadowRoot.querySelectorAll(
        '.fil-media-grid__section'
      );
      const sectionHeight =
        sectionNodes.length === 2
          ? sectionNodes[1].offsetHeight
          : VIDEO_MAX_HEIGHT;
      let height = sectionHeight;
      if (sectionHeight <= VIDEO_MIN_HEIGHT) {
        height = VIDEO_MIN_HEIGHT;
      } else if (sectionHeight >= VIDEO_MAX_HEIGHT) {
        height = VIDEO_MAX_HEIGHT;
      }
      const style = {
        height: `${height}px`,
      };

      return html`<div
        class="fil-media-grid__video-wrapper"
        style=${styleMap(style)}
      >
        ${isWebMedia
          ? html`<video controls src="${this.videoUrl}">
              Sorry, your browser does not support embedded videos, but do not
              worry, you can
              <a href="${this.videoUrl}">download it</a>
              and watch it with your favorite video player!
            </video>`
          : html`<iframe
              title="player"
              id="iframe-video"
              name="iframe-video"
              scrolling="yes"
              frameborder="0"
              src="${this.videoUrl}"
              allow="fullscreen"
              allowfullscreen=""
              >Your browser must support iframes to play this content.</iframe
            >`}
        <div
          class="fil-media-grid__video-image"
          style=${styleMap(styles)}
        ></div>

        <div class="fil-media-grid__icon-container">
          <fil-icon
            class="fil-media-grid__icon"
            icon="arrow-button-right-2"
            size="24"
            @click="${this.handlePlayer}"
          >
          </fil-icon>
        </div>
      </div>`;
    }
  }

  handlePlayer() {
    const video = this.shadowRoot.querySelector('video');
    if (video) {
      video.play();
    }

    const videoIframe = this.shadowRoot.querySelector('iframe');
    if (videoIframe) {
      let videoUrl = videoIframe.src;
      const playStr = 'true';
      var newVideoUrl = videoUrl.replace(
        /(autoPlay=).*?(&)/,
        '$1' + playStr + '$2'
      );
      videoIframe.src = newVideoUrl;
    }

    const videoImage = this.shadowRoot.querySelector(
      '.fil-media-grid__video-image'
    );
    const videoPlayIcon = this.shadowRoot.querySelector(
      '.fil-media-grid__icon-container'
    );
    videoImage.classList.add('is-hidden');
    videoPlayIcon.classList.add('is-hidden');
  }

  handlePlayerEnd() {
    const videoImage = this.shadowRoot.querySelector(
      '.fil-media-grid__video-image'
    );
    videoImage.classList.remove('is-hidden');
  }

  render() {
    const orderClass = this.getMediaAlign();

    return html`
      <section
        class="fil-media-grid fil-media-grid--${this.variant} ${orderClass} "
      >
        <div class="fil-media-grid__section">${this.mediaTemplate()}</div>
        <div class="fil-media-grid__section">
          <h2 class="fil-media-grid__header">${this.header}</h2>
          <p class="fil-media-grid__content">
            <slot></slot>
          </p>
          ${this.ctaTemplate()}
        </div>
      </section>
    `;
  }
}

customElements.define('fil-text-media-grid', MediaGridComponent);
