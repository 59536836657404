//Iterate and rendered h6 sub header article titles

export class ArticleSections {

  constructor(rootEl) {
    this.$headers = rootEl.find('h6:not(.article_author_name)');
  }

  /**
   * Count the number of header elements
   *
   * @return {number} Count of headers
   */
  count() {
    return this.$headers.length;
  }

  /**
   * Iterate through the headers, add named anchors and generate index list
   *
   * @param {jQuery} $listContainer List container for adding jump links to
   */
  createList($listContainer) {
    this.$headers.each(function (index) {
      $(this).wrap(`<div class="section-header" id="section${index}"></div>`);
      const headerTitle = $(this).text();
      const sectionHtml = `<li><a href="#section${index}">${headerTitle}</a></li>`;
      $listContainer.append(sectionHtml);
    });
  }
}

export const insertSectionsList = () => {
  // Initialise the ArticleSection index on the page.

  // Build and Render Section list
  const $content = $('.fil-article-page');

  if (!$content.length) {
    return;
  }

  const sections = new ArticleSections($content);

  // Only add index if there are more than 3 section
  if (sections.count() <= 3) {
    return;
  }

  const $sectionContainer = $content.find('.fil-article-sections');
  const $sectionList = $sectionContainer.find('.fil-article-sections__list');
  sections.createList($sectionList);

  const $firstParagraph = $('.fil-article-content > p:first-of-type');
  $firstParagraph.after($sectionContainer);
  $sectionContainer.removeClass('hide');
};
