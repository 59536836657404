import { html, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';

import baseStyles from '../base/base.styles';
import componentStyles from './fil-tabs.styles';

// used for generating unique id for each tab
let tabIdCounter = 0;

class TabsComponent extends LitElement {
  static get properties() {
    return {
      label: { type: String },
      icon: { type: String },
      active: { type: Boolean },
      moreText: { type: String },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
  }

  firstUpdated() {
    super.firstUpdated();
    // create an unique id
    this.id = this.id || `fil-tab-${++tabIdCounter}`;
  }

  render() {
    return html`
      <div
        class="tabs-panel ${classMap({
          show: this.active,
          hidden: !this.active,
        })}"
      >
        <slot></slot>
      </div>
    `;
  }
}

customElements.define('fil-tab', TabsComponent);
