import { LitElement, html, css } from 'lit-element';
import iconStyles from './fil-icon.styles';
import iconsMap from './fil-icon.map';

export class IconComponent extends LitElement {
  static get properties() {
    return {
      icon: { type: String },
      size: { type: String },
      label: { type: String },
      position: { type: String },
      filled: { type: Boolean },
      color: { type: String },
    };
  }

  static get styles() {
    return [iconStyles];
  }

  svgModifier(svg) {
    if (!svg) return;
    if (this.label) {
      svg.setAttribute('aria-role', 'img');
      svg.setAttribute('aria-label', this.label);
    } else {
      svg.setAttribute('aria-hidden', 'true');
    }
    if (this.size) {
      svg.setAttribute('data-size', this.size);
      svg.classList.remove("inherit-size");
    } else {
      svg.removeAttribute('data-size');
      svg.classList.add("inherit-size");
    }
    if (this.position) {
      svg.setAttribute('data-position', this.position);
    } else {
      svg.removeAttribute('data-position');
    }
    if (this.filled) {
      svg.setAttribute('data-filled', 'true');
    } else {
      svg.removeAttribute('data-filled');
    }
    if (this.color) {
      svg.setAttribute('data-color', this.color);
    } else {
      svg.removeAttribute('data-color');
    }
    return svg;
  }

  render() {
    const template = iconsMap[this.icon];
    if (template) {
      let html_string = template().getHTML();
      let el = document.createElement('div');
      el.innerHTML = html_string;
      let svg = this.svgModifier(el.getElementsByTagName('svg')[0]);
      return html`${svg}`;
    }
    return null;
  }
}

customElements.define('fil-icon', IconComponent);
