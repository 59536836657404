import { html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { ifDefined } from 'lit-html/directives/if-defined';
import { inputMask } from '@fil-global/gds-components/scripts/text-input/text-input';
import { dispatchEvent } from '@fil-global/gds-components/scripts/util/util';

import { STATUS } from '../base/text-field/util';
import { ifNonEmpty } from '../globals/directives/isNotNull';
import componentStyles from './fil-text-input.styles';
import { TextFieldBase } from '../base/text-field/text-field.base';
import '../fil-icon/fil-icon.component';

export class TextInputComponent extends TextFieldBase {
  static get properties() {
    return {
      /**
       * @type {"text" | "date" | "password"}
       */
      type: {
        type: String,
      },
      /**
       * @type {"number" | "dob" | "currency" | "percentage" | "nationalInsuranceNumber"}
       */
      mask: {
        type: String,
      },
      size: { type: String },
      placeholder: { type: String },
      maxlength: { type: Number },
      min: { type: Number },
      max: { type: Number },
      characterCount: { type: Boolean },
      readonly: { type: Boolean },
      required: { type: Boolean },
      passwordHints: { type: Array },
      icon: { type: String },
      autocomplete: { type: String },
      autofocus: { type: Boolean },
      pattern: { type: String },
    };
  }

  static get styles() {
    return [super.styles, componentStyles];
  }

  constructor() {
    super();
    this.type = 'text';
    this.maxlength = 100;
    this.min = this.type === 'number' ? this.min : undefined;
    this.max = this.type === 'number' ? this.max : undefined;
    this.readonly = false;
    this.required = false;
    this.autocomplete = '';
    this.autofocus = false;
    this.pattern = '';
  }

  handleIconClick() {
    dispatchEvent(this, 'click.gds.input', { target: 'icon' });
  }

  normalIconTemplate(className) {
    const { icon } = this;
    const size = this.size ? 16 : 20;
    return html`<fil-icon
      icon=${icon}
      size=${size}
      class=${className}
      @click=${this.handleIconClick}
    ></fil-icon>`;
  }

  iconTemplate() {
    const { icon, status } = this;
    const className = 'fil-text-field__icon';
    const size = this.size ? 16 : 20;

    // decide which icon should be used according to status
    let iconStatus = '';
    if (!status === STATUS.DISABLED) {
      if (status === STATUS.INVALID) {
        iconStatus = 'custom-alert-circle';
      } else if (status === STATUS.VALID) {
        iconStatus = 'custom-check-circle-1';
      } else {
        iconStatus = '';
      }
    }

    if (!icon) {
      if (status === STATUS.VALID) {
        return html`
          <fil-icon icon="custom-check-circle-1" size=${size} filled class=${className}></fil-icon>
        `;
      }
      if (status === STATUS.INVALID) {
        return html`
          <fil-icon icon="custom-alert-circle" size=${size} filled class=${className}></fil-icon>
        `;
      }
    } else if (icon && status !== STATUS.INVALID && status !== STATUS.VALID) {
      return html`${this.normalIconTemplate('fil-text-field__icon')}`;
    } else {
      return html` ${this.normalIconTemplate('fil-text-field__icon fil-text-field__icon--normal')}
      ${status === STATUS.DISABLED
        ? ''
        : html`<fil-icon icon=${iconStatus} size=${size} filled class=${className}></fil-icon>`}`;
    }

    return null;
  }

  updated() {
    super.updated();
    const el = this.shadowRoot.querySelector('input');
    if (this.mask) {
      inputMask(el, this.mask);
    }
  }

  characterCountTemplate() {
    if (this.characterCount) {
      const value = this.value ? this.value : '';
      return html`
        <p class="fil-text-input__character-count" aria-hidden="true">
          ${value.length}/${this.maxlength}
        </p>
      `;
    }
    return null;
  }

  passwordHintTemplate() {
    if (this.passwordHints) {
      const classes = {
        'fil-password-hint': true,
        'fil-password-hint--enter': this.type === 'password',
        'fil-password-hint--create': this.type === 'text',
      };
      return html`<div class=${classMap(classes)}>
        ${this.type === 'password'
          ? html`<p class="fil-password-hint__intro">The password must include:</p>`
          : ''}
        <ul class="fil-password-hint__list">
          ${this.passwordHints.map(passwordHint => {
            const pwdClasses = {
              'fil-password-hint__item': true,
              'fil-password-hint__item--active': passwordHint.active,
            };
            return html`<li class=${classMap(pwdClasses)}>${passwordHint.label}</il>`;
          })}
        </ul>
      </div>`;
    }
    return null;
  }

  handleKeyUp(e) {
    const { value } = e.target;
    this.value = value;

    // handle password validation
    if (this.passwordHints) {
      this.passwordHints = this.passwordHints.map(passwordHint => {
        const regex = RegExp(passwordHint.regex);
        return {
          ...passwordHint,
          active: regex.test(value),
        };
      });
    }

    dispatchEvent(this, 'onkeyup keyup.gds.input', { value });
  }

  handleChange(e) {
    dispatchEvent(this, 'onchange change.gds.input', {
      value: e.target.value,
      status: this.status,
    });
  }

  handleFocus(e) {
    dispatchEvent(this, 'onfocus focus.gds.input', { value: e.target.value });
  }

  handleBlur(e) {
    dispatchEvent(this, 'onblur blur.gds.input', { value: e.target.value });
  }

  renderElement() {
    const disabled = this.disabled || this.status === STATUS.DISABLED;
    const classes = {
      'fil-text-input': true,
      'fil-text-input--inversed': this.inversed,
      'fil-text-input--small': this.size === 'small',
      'fil-text-input--valid': this.status === STATUS.VALID,
      'fil-text-input--invalid': this.status === STATUS.INVALID,
      'fil-text-input--disabled': disabled,
    };
    return html`
      <input
        type=${this.type}
        id=${this.elementId}
        name=${this.name}
        class=${classMap(classes)}
        .value=${ifDefined(this.value)}
        maxlength=${this.maxlength}
        ?autocomplete="${this.autocomplete}"
        ?autofocus="${this.autofocus}"
        .min=${ifDefined(this.min)}
        .max=${ifDefined(this.max)}
        ?disabled=${disabled}
        ?required=${this.required}
        ?readonly=${this.readonly}
        pattern="${ifNonEmpty(this.pattern)}"
        placeholder="${ifNonEmpty(this.placeholder)}"
        aria-required=${this.required}
        aria-readonly=${this.readonly}
        aria-labelledby="label_${this.elementId}"
        @keyup=${this.handleKeyUp}
        @change=${this.handleChange}
        @focus=${this.handleFocus}
        @blur=${this.handleBlur}
      />${this.passwordHintTemplate()} ${this.iconTemplate()} ${this.characterCountTemplate()}
    `;
  }
}

customElements.define('fil-text-input', TextInputComponent);
