import { ifDefined } from 'lit-html/directives/if-defined';

/**
 * A variant of `lit-html/directives/if-defined` which stops rendering if the given value is `null` in addition to `undefined`.
 * @param The value.
 */
export default (value) => ifDefined(value ?? undefined);

/**
 * A variant of `if-non-null` which stops rendering if the given value is an emptry string in addition to `null`/`undefined`.
 * @param The value.
 */
export const ifNonEmpty = (value) =>
  ifDefined(value === '' ? undefined : value ?? undefined);
