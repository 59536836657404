import { LitElement, html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import uid from 'uid';

import ifNonNull from '../../globals/directives/isNotNull';
import baseStyles from '../base.styles';
import formFieldStyles from './text-field.styles';
import { STATUS } from './util';
import '../../fil-tooltip/fil-tooltip.component';

export class TextFieldBase extends LitElement {
  static get properties() {
    return {
      inversed: {
        type: Boolean,
      },
      /**
       * @type STATUS
       */
      status: {
        type: String,
      },
      /**
       * @type {"1-col" | "2-cols"}
       */
      layout: { type: String },
      label: { type: String },
      disabled: { type: Boolean },
      assistiveText: { type: String },
      errorText: { type: String },
      value: { type: String },
      /**
       * @type {"dob" | "currency" | "nationalInsuranceNumber"}
       */
      mask: { type: String },
      required: { type: Boolean },
      tooltip: { type: String },
      tooltipExtraProps: { type: Object },
      name: { type: String },
    };
  }

  static get styles() {
    return [baseStyles, formFieldStyles];
  }

  constructor() {
    super();
    this.layout = '1-col';
    this.elementId = uid();
    this.assistiveTextId = this.describedbyId('assitive-text');
    this.errorTextId = this.describedbyId('error-text');
    this.tooltipId = this.describedbyId('tooltip');
    this.tooltipExtraProps = { position: 'bottom', alignment: 'right' };
    this.disabled = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.invalid = this.status === STATUS.INVALID;
  }

  describedbyId(type) {
    return `${this.elementId}-${type}`;
  }

  updated() {
    const el = this.shadowRoot.querySelector('select,textarea,input');
    const describedByIds = [];
    if (el) {
      if (this.status === STATUS.INVALID) {
        el.setAttribute('aria-invalid', 'true');
      } else {
        el.removeAttribute('aria-invalid');
      }
      if (this.assistiveText) {
        describedByIds.push(this.assistiveTextId);
      } else if (this.errorText) {
        describedByIds.push(this.errorTextId);
      } else if (this.tooltip) {
        describedByIds.push(this.tooltipId);
      } else if (describedByIds.length > 0) {
        el.setAttribute('aria-describedby', describedByIds.join(' '));
      }
    }
  }

  maskTemplate() {
    if (this.mask === 'percentage') {
      return html` <span class="fil-text-field__mask">%</span> `;
    }
    return null;
  }

  labelTemplate() {
    if (this.label) {
      return html`
        <label class="fil-text-field__label" for=${this.name} id="label_${this.elementId}">
          ${this.required
            ? html` <span class="fil-text-field__required" aria-label="Required">*</span> `
            : ''}
          ${this.label}</label
        >
      `;
    }
    return null;
  }

  assistiveTextTemplate() {
    if (this.assistiveText) {
      return html`
        <p class="fil-text-field__assistive-text" id="${this.assistiveTextId}">
          ${this.assistiveText}
        </p>
      `;
    }
    return null;
  }

  tooltipTemplate() {
    if (this.tooltip) {
      return html`
        <div class="fil-text-field__tooltip">
          <div class="tooltip">
            <span aria-hidden="true" class="tooltip__toggle">
              <fil-tooltip
                message=${this.tooltip}
                extraProps=${ifNonNull(this.tooltipExtraProps)
                  ? JSON.stringify({ ...this.tooltipExtraProps })
                  : null}
              ></fil-tooltip>
            </span>
          </div>
        </div>
      `;
    }
    return null;
  }

  errorTextTemplate() {
    if (this.errorText && this.status === STATUS.INVALID) {
      return html`
        <p class="fil-text-field__error-text" id="${this.errorTextId}">${this.errorText}</p>
      `;
    }
    return null;
  }

  renderTemplates() {
    return html`
      <div class="fil-text-field__description">
        ${this.labelTemplate()} ${this.assistiveTextTemplate()} ${this.errorTextTemplate()}
        ${this.tooltipTemplate()}
      </div>
      <div class="fil-text-field__item">${this.renderElement()} ${this.maskTemplate()}</div>
    `;
  }

  render() {
    const classes = {
      'fil-text-field': true,
      'fil-text-field--inversed': this.inversed,
      'fil-text-field--valid': this.status === STATUS.VALID,
      'fil-text-field--invalid': this.status === STATUS.INVALID,
      'fil-text-field--2-cols': this.layout === '2-cols',
      'fil-text-field--disabled': this.disabled || this.status === STATUS.DISABLED,
    };

    const result = html` <div class=${classMap(classes)}>${this.renderTemplates()}</div> `;

    return result;
  }
}
