import { html, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';

import { channel, textKind, truncateCharacters } from './util';
import baseStyles from '../base/base.styles';
import componentStyles from './fil-hero-home.styles';

class HeroIntro extends LitElement {
  static get properties() {
    return {
      title: { type: String },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  renderTitle() {
    if (!this.title) return null;
    const truncatedTitle = truncateCharacters(
      this.title,
      channel.INTRO,
      textKind.TITLE
    );
    return html`<h1 class="fil-hero-intro__title">${truncatedTitle}</h1>`;
  }

  renderHeroIntro() {
    return html`<div class="fil-hero-intro">
      ${this.renderTitle()}
      <div class="fil-hero-intro__content">
        <slot></slot>
      </div>
    </div>`;
  }

  render() {
    return html`
      <div class="fil-hero-intro-wrapper">${this.renderHeroIntro()}</div>
    `;
  }
}

customElements.define('fil-page-hero', HeroIntro);
