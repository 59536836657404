import { html, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';

import {
  channel,
  cardSize,
  textKind,
  alignment,
  truncateCharacters,
} from './util';
import baseStyles from '../base/base.styles';
import componentStyles from './fil-hero-home.styles';

class HeroCard extends LitElement {
  static get properties() {
    return {
      title: { type: String },
      /**
       * normal, small, default value is 'normal', it means the card style is Feature style.
       */
      size: { type: String },
      /**
       * right, left. if don't set, card will align left.
       */
      alignment: { type: String },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
    this.size = cardSize.NORMAL;
    this.alignment = alignment.LEFT;
  }

  renderTitle() {
    if (!this.title) return null;
    if (this.size === cardSize.NORMAL) {
      const truncatedTitle = truncateCharacters(
        this.title,
        channel.CARD,
        textKind.TITLE
      );
      return html`<h2 class="fil-hero-card__title">${truncatedTitle}</h2>`;
    } else {
      const truncatedTitle = truncateCharacters(
        this.title,
        channel.CARD_SMALL,
        textKind.TITLE
      );
      return html`<h1 class="fil-hero-card__title">${truncatedTitle}</h1>`;
    }
  }

  renderHeroCard() {
    return html`<div class="fil-hero-card">
      ${this.renderTitle()}
      <div class="fil-hero-card__content">
        <slot name="hero-card-content"></slot>
      </div>
      <div class="fil-hero-card__cta">
        <slot name="card-cta"></slot>
      </div>
    </div>`;
  }

  render() {
    const classes = {
      'fil-hero-card-wrapper': true,
      'fil-hero-card-wrapper--right':
        this.alignment === alignment.RIGHT ? true : false,
      'fil-hero-card-wrapper--small':
        this.size === cardSize.NORMAL ? false : true,
    };

    return html`
      <div class=${classMap(classes)}>${this.renderHeroCard()}</div>
    `;
  }
}

customElements.define('fil-hero-home-card', HeroCard);
