/* eslint-disable no-nested-ternary */
import { html, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';

import baseStyles from '../base/base.styles';
import componentStyles from './fil-editorial-card.styles';
import '../fil-author/fil-author.component';

class EditorialCardComponent extends LitElement {
  static get properties() {
    return {
      inversed: { type: Boolean },
      url: { type: String },
      image: { type: String },
      category: { type: String },
      categoryurl: { type: String },
      headline: { type: String },
      subtext: { type: String },
      author: { type: Array },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  linkTemplate() {
    // eslint-disable-next-line no-nested-ternary
    return html`<a tabindex="-1" href="${this.url}" class="fil-media-card__headline"
      >${this.headline}</a
    >`;
  }

  render() {
    const classes = {
      'fil-media-card': true,
      'fil-media-card--inversed': this.inversed,
    };
    return html`
      <div class="${classMap(classes)}">
        ${this.image
          ? html`
              <div
                class="fil-media-card__image"
                style="background-image:url('${this.image}')"
              ></div>
            `
          : ''}
        <div class="fil-media-card__content">
          ${this.categoryurl
            ? html`
                <div class="fil-media-card__category">
                  <a href="${this.categoryurl}">${this.category}</a>
                </div>
              `
            : this.category
            ? html` <span class="fil-media-card__category">${this.category}</span> `
            : ''}
          <section tabindex="0">
            ${this.subtext || this.author || this.date
              ? html` <h3 class="fil-media-card__title">${this.linkTemplate()}</h3> `
              : this.linkTemplate()}
            ${this.subtext ? html` <p class="fil-media-card__subtext">${this.subtext}</p> ` : ''}
          </section>
        </div>
        <div class="fil-media-card__footer">
          ${this.author
            ? html`
                <p class="fil-media-card__author-and-date">
                  <fil-author size="small" authors=${JSON.stringify(this.author)}></fil-author>
                </p>
              `
            : ''}
        </div>
      </div>
    `;
  }
}

customElements.define('fil-editorial-card', EditorialCardComponent);
