/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
import { html, LitElement } from 'lit-element';
import { getDeviceType, SMALL, dispatchEvent } from '@fil-global/gds-components/scripts/util/util';

import baseStyles from '../base/base.styles';
import componentStyles from './fil-tabs.styles';
import '../fil-icon/fil-icon.component';

class TabsComponent extends LitElement {
  static get properties() {
    return {
      activeIndex: { type: Number },
      tabsSlot: { type: Array },
      activeLink: { type: Object },
      disabled: { type: Boolean },
      moreText: { type: String },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
    this.activeIndex = 0;
    this.moreText = 'More';
    this.tabsSlot = [];
    this.initialized = false;
    // window.resize is on outside of this component, so should bind this.
    this.handleResize = this.handleResize.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  updated(changedProperties) {
    if (changedProperties.has('activeIndex') && !changedProperties.has('activeLink')) {
      const tabs = this.shadowRoot.querySelectorAll('li.tabs-title:not(.fil--hidden) a');
      tabs[this.activeIndex].click();
    }
  }

  shouldUpdate(changedProperties) {
    if (!this.initialized) {
      this.tabsSlot = Array.from(this.children);
      // eslint-disable-next-line array-callback-return
      this.tabsSlot.map((panel, index) => {
        panel.setAttribute('tabIndex', '0');
        if (index === this.activeIndex) {
          panel.setAttribute('active', 'true');
        } else {
          panel.removeAttribute('active');
        }
      });
      this.initialized = true;
    }

    return changedProperties;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('click', this.handleClickOutside);
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('click', this.handleClickOutside);
    super.disconnectedCallback();
  }

  handleResize() {
    this.clearHiddenTabs();
    if (!this.isMobile) {
      this.calculateLinks();
    }
  }

  handleClickOutside(e) {
    if (e.target.localName !== 'fil-tabs') {
      const container = this.shadowRoot.querySelector('.fil-tabs--wrapper');
      container.classList.remove('show-secondary');
    }
  }

  updateTabs(target, activeIndex) {
    this.tabsSlot.map((panel, index) => {
      if (panel.hasAttribute('active') && panel !== target) {
        panel.removeAttribute('active');
      }

      if (panel === target || (activeIndex && activeIndex === index)) {
        if (!panel.hasAttribute('active')) {
          panel.setAttribute('active', 'true');
        }
      }
    });

    return this.tabsSlot;
  }

  get isMobile() {
    return SMALL === getDeviceType();
  }

  render() {
    return html`
      <div class="fil-tabs--wrapper">
        <ul class="tabs" role="tablist" aria-label="tablist"></ul>
        <div class="tabs-content">
          <slot></slot>
        </div>
      </div>
    `;
  }

  handleClick(index) {
    // dispatch selected tab index, index starts from 0
    dispatchEvent(this, 'onclick click.gds.tabs', { value: index });
  }

  firstUpdated() {
    const container = this.shadowRoot.querySelector('.fil-tabs--wrapper');
    const tabLinksContainer = container.querySelector('ul');
    let hasIcon = false;

    // TODO I'm not sure how to use html `` to replace it
    this.tabsSlot.map((tab, index) => {
      const isDisabled = tab.hasAttribute('disabled');
      const liLink = document.createElement('li');
      liLink.classList.add('tabs-title');
      liLink.setAttribute('data-index', index);
      liLink.setAttribute('tabindex', 0);
      liLink.setAttribute('role', 'tab');
      liLink.setAttribute('aria-label', tab.label);
      liLink.setAttribute('aria-selected', this.activeLink === liLink);
      if (isDisabled) {
        liLink.setAttribute('disabled', 'disabled');
      } else {
        liLink.removeAttribute('disabled');
      }
      liLink.innerHTML = `${
        tab.icon ? `<fil-icon class="fi-icon" icon="${tab.icon}" size="24"> </fil-icon>` : ''
      } <a href="javascript: undefined" tabindex="-1" ${
        isDisabled ? 'class="is-disabled-link"' : ''
      }>${tab.label}</span>`;

      if (index === this.activeIndex) {
        this.activeLink = liLink;
        liLink.classList.add('is-active');
      }
      // eslint-disable-next-line consistent-return
      liLink.addEventListener('click', () => {
        if (isDisabled) return false;
        this.activeLink.classList.remove('is-active');
        liLink.classList.add('is-active');
        this.activeIndex = index;
        this.activeLink = liLink;
        this.updateTabs(tab, this.activeIndex);
        container.classList.remove('show-secondary');
        this.handleClick(index);
      });
      liLink.addEventListener('keypress', e => {
        if (e.keyCode === 13) {
          e.target.click();
        }
      });

      if (tab.icon) {
        hasIcon = true;
      }
      tabLinksContainer.appendChild(liLink);
    });

    if (hasIcon) {
      tabLinksContainer.classList.add('fil-icons--type');
    }

    container.classList.add('is--jsfied');

    // insert more link and dropdown list
    this.insertMore(tabLinksContainer);

    // add click listener to more button
    const moreBtn = tabLinksContainer.querySelector('button');
    moreBtn.addEventListener('click', () => {
      container.classList.toggle('show-secondary');
    });

    // script block, may run before the browser parses the external stylesheet to apply the display: flex style.
    setTimeout(() => {
      this.calculateLinks();
      this.equalLinks(hasIcon);
      this.clearHiddenTabs();
      this.calculateLinks();
      this.appendDropdownListener();
    }, 50);

    const link2 = this.shadowRoot.querySelector('.is-disabled-link');
    link2.parentNode.classList.add('is-disabled');
  }

  insertMore(tabLinksContainer) {
    const liLink = document.createElement('li');
    liLink.classList.add('fil-more--menu');
    liLink.innerHTML = `<button type="button" aria-haspopup="true" aria-expanded="false">
            <fil-icon icon="navigation-menu-horizontal" filled class="fil-icon--more" size="16"></fil-icon>
            <span class="fil-more">${this.moreText}</span>
          </button>
          <ul class="tab-secondary">${tabLinksContainer.innerHTML}</ul>`;
    tabLinksContainer.appendChild(liLink);
  }

  appendDropdownListener() {
    const container = this.shadowRoot.querySelector('.fil-tabs--wrapper');
    const tabsBarLinks = container.querySelectorAll('.tabs>li.tabs-title:not(.more)');
    const secondaryLinks = container.querySelectorAll('.tab-secondary li');
    secondaryLinks.forEach((item, index) => {
      item.addEventListener('click', () => {
        const tabBarLink = container.querySelector(`.tabs li[data-index="${index}"]`);
        const shownLinks = container.querySelectorAll('.tabs>li.tabs-title:not(.fil--hidden)');

        const lastShownLink = shownLinks[shownLinks.length - 1];
        const lastShownLinkIndex = lastShownLink.getAttribute('data-index');
        lastShownLink.classList.remove('is-active');
        lastShownLink.classList.add('fil--hidden');

        tabBarLink.classList.add('is-active');
        tabBarLink.classList.remove('fil--hidden');
        item.classList.add('fil--hidden');
        secondaryLinks[lastShownLinkIndex].classList.remove('fil--hidden');
        // remove current active index
        tabsBarLinks[this.activeIndex].classList.remove('is-active');
        this.activeIndex = index;
        this.activeLink = tabBarLink;
        this.updateTabs(tabBarLink, this.activeIndex);
        container.classList.toggle('show-secondary');
        this.handleClick(index);
      });
    });
  }

  equalLinks(hasIcon) {
    const tabLinks = this.shadowRoot.querySelectorAll('ul.tabs>li.tabs-title>a');
    let maxWidth = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const tabLink of tabLinks) {
      maxWidth = Math.max(maxWidth, tabLink.offsetWidth);
      if (!maxWidth) {
        return;
      }
      tabLink.style.width = `${maxWidth}px`;
    }
    const secondary = this.shadowRoot.querySelector('.tab-secondary');
    secondary.style.width = !hasIcon ? `${maxWidth}px` : `${maxWidth + 37}px`;
  }

  clearHiddenTabs() {
    const container = this.shadowRoot.querySelector('.fil-tabs--wrapper');
    const allItems = container.querySelectorAll('.tabs li');
    allItems.forEach(item => {
      item.classList.remove('fil--hidden');
    });
  }

  calculateLinks() {
    const container = this.shadowRoot.querySelector('.fil-tabs--wrapper');
    const primary = container.querySelector('.tabs');
    const primaryItems = container.querySelectorAll('.tabs > li:not(.fil-more--menu)');

    const moreLi = primary.querySelector('.fil-more--menu');
    const moreBtn = moreLi.querySelector('button');

    const secondary = container.querySelector('.tab-secondary');
    const secondaryItems = secondary.querySelectorAll('li');

    // hide items that won't fit in the Primary
    let stopWidth = 90;
    const hiddenItems = [];
    const primaryWidth = primary.offsetWidth;
    primaryItems.forEach((item, i) => {
      if (primaryWidth >= stopWidth + item.offsetWidth) {
        stopWidth += item.offsetWidth;
      } else {
        hiddenItems.push(i);
      }
    });

    // toggle the visibility of More button and items in Secondary and tabBar
    if (hiddenItems.length === 0) {
      moreLi.classList.add('fil--hidden');
      container.classList.remove('show-secondary');
      moreBtn.setAttribute('aria-expanded', false);
    } else {
      primaryItems.forEach((item, i) => {
        if (hiddenItems.includes(i)) {
          item.classList.add('fil--hidden');
        }
      });

      secondaryItems.forEach((item, i) => {
        if (!hiddenItems.includes(i)) {
          item.classList.add('fil--hidden');
        }
      });
    }
  }
}

customElements.define('fil-tabs', TabsComponent);
