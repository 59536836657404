export const channel = {
  CARD: 'card',
  CARD_SMALL: 'smallCard',
  INTRO: 'intro',
  BLOCK: 'block',
};

export const characterLimitation = {
  CARD: {
    NORMAL: {
      TITLE: 60,
      BODY: 220,
    },
    SMALL: {
      TITLE: 60,
      BODY: 170,
    },
  },
  BLOCK: {
    TITLE: 32,
    BODY: 132,
  },
  INTRO: {
    TITLE: 60,
    BODY: 170,
  },
};

export const cardSize = {
  NORMAL: 'normal',
  SMALL: 'small',
};

export const alignment = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const textKind = {
  TITLE: 'title',
  BODY: 'body',
};

/*
 * style theme
 */
export const theme = {
  NORMAL: 'normal',
  DARK: 'dark',
};

/*
 * truncate characters with limitation
 * @flag: channel defined in top of this file
 */
export const truncateCharacters = (text, flag, titleOrBody) => {
  if (!text) {
    return '';
  }
  if (!flag || !titleOrBody) {
    return text;
  }
  let truncatedText = text;
  switch (flag) {
    case channel.CARD:
      if (
        titleOrBody === textKind.TITLE &&
        truncatedText.length > characterLimitation.CARD.NORMAL.TITLE
      ) {
        truncatedText = `${truncatedText.substring(
          0,
          characterLimitation.CARD.NORMAL.TITLE + 1
        )}`;
      } else if (
        titleOrBody === textKind.BODY &&
        truncatedText.length > characterLimitation.CARD.NORMAL.BODY
      ) {
        truncatedText = `${truncatedText.substring(
          0,
          characterLimitation.CARD.NORMAL.BODY + 1
        )}`;
      }
      break;
    case channel.CARD_SMALL:
      if (
        titleOrBody === textKind.TITLE &&
        truncatedText.length > characterLimitation.BLOCK.TITLE
      ) {
        truncatedText = `${truncatedText.substring(
          0,
          characterLimitation.BLOCK.TITLE + 1
        )}`;
      } else if (
        titleOrBody === textKind.BODY &&
        truncatedText.length > characterLimitation.BLOCK.BODY
      ) {
        truncatedText = `${truncatedText.substring(
          0,
          characterLimitation.BLOCK.BODY + 1
        )}`;
      }
      break;
    case channel.BLOCK:
      if (
        titleOrBody === textKind.TITLE &&
        truncatedText.length > characterLimitation.BLOCK.TITLE
      ) {
        truncatedText = `${truncatedText.substring(
          0,
          characterLimitation.BLOCK.TITLE + 1
        )}`;
      } else if (
        titleOrBody === textKind.BODY &&
        truncatedText.length > characterLimitation.BLOCK.BODY
      ) {
        truncatedText = `${truncatedText.substring(
          0,
          characterLimitation.BLOCK.BODY + 1
        )}`;
      }
      break;
    case channel.INTRO:
      if (
        titleOrBody === textKind.TITLE &&
        truncatedText.length > characterLimitation.INTRO.TITLE
      ) {
        truncatedText = `${truncatedText.substring(
          0,
          characterLimitation.INTRO.TITLE + 1
        )}`;
      } else if (
        titleOrBody === textKind.BODY &&
        truncatedText.length > characterLimitation.INTRO.BODY
      ) {
        truncatedText = `${truncatedText.substring(
          0,
          characterLimitation.INTRO.BODY + 1
        )}`;
      }
      break;
  }
  return truncatedText;
};
