const TAB_HASH_KEY = 'tab';
class FILTab {
  constructor(root) {
    this.root = root;
    this.$tab = $('fil-tabs', root);
    this.bindEvents();
    this.parseStatesFromLocation();
  }

  parseStatesFromLocation() {
    this.searchParams = new URLSearchParams(window.location.hash.substring(1));
    const activeTabId = this.searchParams.get(TAB_HASH_KEY);
    if (activeTabId) {
      this.updateActiveIndex(activeTabId);
    }
  }

  bindEvents() {
    const tab = this.$tab[0];
    this.activeIndex = tab.activeIndex;
    tab.addEventListener('click.gds.tabs', evt => {
      const activeIndex = tab.activeIndex;
      if (activeIndex !== this.activeIndex) {
        this.updateLocationHash();
        this.activeIndex = activeIndex;
      }
    });
  }

  updateActiveIndex(activeTabId) {
    const tab = this.$tab[0];
    const tabItem = this.$tab.find(`fil-tab[tab-id="${activeTabId}"]`)[0];
    const activeIndex = parseInt(tabItem.getAttribute('item-index'));
    if (tab.activeIndex !== activeIndex) {
      tab.activeIndex = activeIndex;
    }
  }

  updateLocationHash() {
    const tab = this.$tab[0];
    const dataIndex = tab.activeLink.getAttribute('data-index');
    const $tabItem = this.$tab.find('fil-tab').eq(dataIndex);
    const anchor = $tabItem.attr('tab-id');
    if (anchor) {
      location.hash = `${TAB_HASH_KEY}=${anchor}`;
    }
  }
}

$(function() {
  $('.fil-tabs').each((_, root) => {
    new FILTab(root);
  });
});
