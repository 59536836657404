import { html, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { styleMap } from 'lit-html/directives/style-map';
import uid from 'uid';
import { dispatchEvent } from '@fil-global/gds-components/scripts/util/util';

import '../fil-icon';
import baseStyles from '../base/base.styles';
import componentStyles from './fil-accordion.styles';

class AccordionItemComponent extends LitElement {
  static get properties() {
    return {
      expanded: { type: Boolean },
      // every item is in its own shadowDom,fil-accordion__item:first-of-type can't work, define this property for matching design
      first: { type: Boolean },
      label: { type: String },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();

    this.expanded = false;
    this.label = '';
    this.uid = uid();
  }

  popupEvent(eventName, eventKey) {
    dispatchEvent(this, eventName, { innerAction: true, key: eventKey, expanded: !this.expanded });
  }

  toggleHandler(e) {
    this.popupEvent('onclick click.gds.accordion', e.key);
  }

  handleKeyUp(e) {
    const accordionItem = this.shadowRoot.querySelector('.fil-accordion__item');
    if (e.target === accordionItem) {
      this.popupEvent('onkeyup keyup.gds.accordion', e.key);
    }
  }

  render() {
    return html`
      <div
        class="fil-accordion__item"
        style=${styleMap({ borderTopWidth: !this.first ? 0 : null })}
        @keyup=${this.handleKeyUp}
      >
        <a
          id="item-${this.uid}"
          class=${classMap({
            'fil-accordion__title': true,
            'fil-accordion__title--active': this.expanded,
          })}
          aria-expanded="${this.expanded}"
          aria-selected="${this.expanded}"
          aria-controls="${this.uid}"
          role="tab"
          @click="${this.toggleHandler}"
        >
          ${this.label}
          <fil-icon
            icon="custom-chevron-down"
            class="fil-accordion__icon"
            size="16"
            tabindex="0"
            role="button"
            @keyup="${e => {
              if (e.keyCode === 13) this.toggleHandler(e);
            }}"
          ></fil-icon>
        </a>
        <div
          id="${this.uid}"
          class="fil-accordion__content"
          aria-labelledby="item-${this.uid}"
          aria-hidden="${!this.expanded}"
          role="tabpanel"
        >
          <p><slot></slot></p>
        </div>
      </div>
    `;
  }

  updated() {
    const accordionContent = this.shadowRoot.querySelector('.fil-accordion__content');
    const accordionContentHeight = `${accordionContent.scrollHeight}px`;

    // It's not possible to do a CSS transform to 'height: auto'. CSS can only transform to defined values like
    // a pixel value. 'height: auto' is necessary to show all content even if its size is unknown which is the case for
    // dynamically loaded content or content which is flexible in its height e.g. containing expandable content.
    //
    // To be able to show an opening and closing animation we simulate this by animating to/from the actual size in the
    // moment we start the transformation.
    //
    // For whatever reason 'overflow:hidden' doesn't prevent adding whitespace at the bottom of the page sometime.
    // For accessibility reason the content will be scaled down to 0 and to 1 accordingly instead of using 'display: none'.
    if (this.expanded) {
      accordionContent.style.height = accordionContentHeight;
      accordionContent.style.transform = 'scale(1)';

      accordionContent.addEventListener(
        'transitionend',
        () => {
          // after the opening transition ends we will remove the height so that it returns to its initial value. Now it
          // changes its height magically to the height of the content inside.
          accordionContent.style.height = 'auto';

          // set the scale to expanded value again. this is necessary if the user re-clicks the accordion multiple times
          // while the (opening) transition is still running to prevent unexpected hidden content
          accordionContent.style.transform = 'scale(1)';
          // deal with accordion content is kind of overlay such as tooltip;
          accordionContent.style.overflow = 'visible';

          // scroll content into viewport
          // accordionItem.scrollIntoView({ behavior: "smooth" })

          dispatchEvent(accordionContent, 'expanded.gds.accordion', { value: this });
        },
        { once: true },
      );
    } else if (!this.expanded) {
      // deal with accordion content is kind of overlay such as tooltip;
      accordionContent.style.overflow = 'hidden';
      // on the next frame, set the element's height to its current height to perform transformation
      requestAnimationFrame(() => {
        accordionContent.style.height = accordionContentHeight;

        // on the next frame, set the element's height to 0
        requestAnimationFrame(() => {
          accordionContent.style.height = 0;

          accordionContent.addEventListener(
            'transitionend',
            () => {
              accordionContent.style.height = 0;

              // set the scale to collapsed value again. this is necessary if the user re-clicks the accordion multiple times
              // while the (closing) transition is still running and prevents unexpected whitespace at the bottom of the page
              accordionContent.style.transform = 'scale(0)';

              dispatchEvent(accordionContent, 'collapsed.gds.accordion', { value: this });
            },
            { once: true },
          );
        });
      });
    }
  }

  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties);

    // for whatever reason Safari doesn't trigger 'transitionend' event for the initial render. To
    // open an accordion with the 'expanded' property correctly in Safari we need to re-render the
    // component after the first render occurred. We do this for the expanded accordion only.
    if (_changedProperties.get('expanded') === undefined && this.expanded) {
      this.requestUpdate();
    }
  }
}

customElements.define('fil-accordion-item', AccordionItemComponent);
