window.FundHelper = {
  getFundNameSpan() {
    const $breadcrumb = $('.tw-fund-wrapper .section-breadcrumb');
    let $fundNameSpan = null;
    if ($breadcrumb.length) {
      $fundNameSpan = $breadcrumb.find('.tw-fund-name');
    }
    return [$fundNameSpan, $breadcrumb];
  },

  updateBreadCrumb(fundName, isDetailPage) {
    const [$fundNameSpan, $breadcrumb] = this.getFundNameSpan();
    if ($fundNameSpan) {
      if ($fundNameSpan.length) {
        $fundNameSpan.html(fundName);
      } else {
        $breadcrumb
          .find('.cell')
          .append(`
          <span class="fil-icon fil-icon-chevron-r-light">\
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fil-icon" fill="none" stroke="currentcolor" data-size="12">\
              <polyline points="7 1 18 12 7 23"></polyline>\
            </svg>\
          </span>\
          <span class="tw-fund-name">${fundName}</span>
          `);

        if (isDetailPage) {
          $breadcrumb.addClass('detail');
        }
      }

      $('html,body').animate({ scrollTop: 0 }, 'slow');
    }
  },

  clearFundNameFromBreadCrumb() {
    const [$fundNameSpan, $breadcrumb] = this.getFundNameSpan();
    if ($fundNameSpan && $fundNameSpan.length) {
      $fundNameSpan.prev('.fil-icon').add($fundNameSpan).remove();
      $breadcrumb.removeClass('detail');
    }
  },

  showSoftClosedBanner() {
    this.$banner = $('.fil-messaging.soft-closed-banner');
    this.$banner.removeClass('hide')
      .find('fil-page-level-message').get(0).show = true;
  },

  hideSoftClosedBanner() {
    this.$banner = $('.fil-messaging.soft-closed-banner');
    this.$banner.addClass('hide')
      .find('fil-page-level-message').get(0).show = false;
  }
};
