import { LitElement, html } from 'lit-element';
import baseStyles from '../base/base.styles';
import componentStyles from './fil-pull-out.styles';
import { classMap } from 'lit-html/directives/class-map';


class PullOutComponent extends LitElement {
  static get properties() {
    return {
      type: { type: String },
      standard: { type: String },
      border: { type: String },
      small: { type: String },
      condition: {type: Boolean },
    };
  }
  static get styles() {
    return [baseStyles, componentStyles];
  }
  constructor() {
    super();
    this.type = 'standard';
    this.border = 'blue';
    this.condition = false;
  }

  bodyTemplate() {
    const border = {
      ['fil-pull-out--border__' + this.border] : this.border,
    }
    return html `
      <div class="fil-pull-out__wrapper">
        <div class="fil-pull-out__content ${classMap(border)}">
          <p class="fil-pull-out__maintext"><slot></slot></p>
          ${this.small
            ? html`
                <p class="fil-pull-out__subtext">
                  ${this.small}
                </p>
              `
            : ''}
        </div>
      </div>
    </div>
    `;
  }

  render() {

    if (this.border === 'grey' & this.type === 'banner' ) {
      this.condition = true;
    } else {
      this.condition = false;
    }
    const classes = {
      ['fil-pull-out'] : true,
      ['fil-pull-out--' + this.type] : this.type,
    }

    return this.condition ? html`
      <div class="fil-pull-out fil-pull-out--banner__grey">
        ${this.bodyTemplate()}
      </div>`
      
      : html`
      <div class=${classMap(classes)}>
      ${this.bodyTemplate()}
      </div>

    `
  }

}  

customElements.define('fil-pull-out', PullOutComponent);
