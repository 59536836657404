import { html } from 'lit-element';
import { ifDefined } from 'lit-html/directives/if-defined';
import { selectMask } from '@fil-global/gds-components/scripts/select-dropdown/select-dropdown';
import { dispatchEvent } from '@fil-global/gds-components/scripts/util/util';
import { classMap } from 'lit-html/directives/class-map';

import '../fil-icon/fil-icon.component';
import '../fil-checkbox/fil-checkbox.component';
import { TextFieldBase } from '../base/text-field/text-field.base';
import checkboxStyles from '../fil-checkbox/fil-checkbox.styles';
import { SELECT_TYPE } from './util';
import componentStyles from './fil-select.styles';

class SelectComponent extends TextFieldBase {
  static get properties() {
    return {
      /**
       * @type SELECT_TYPE
       */
      type: { type: String },
      // TODO why define this prop?
      className: { type: String },
      icon: { type: Boolean },
      placeholder: { type: String },
      options: { type: Array },
      value: { type: String },
      size: { type: String },
      selectedText: { type: String }, // multiple selected text
      extraProps: { type: Object }, // for properties that initialize select
    };
  }

  static get styles() {
    return [super.styles, componentStyles, checkboxStyles];
  }

  constructor() {
    super();
    this.type = SELECT_TYPE.SINGLE;
    this.icon = false;
    this.options = [];
    this.size = 'small';
    this.selectedText = 'Options selected';
  }

  firstUpdated() {
    const root = this.shadowRoot;
    const selectEle = root.querySelector('select');
    const option = {
      // placeholder: !!this.placeholder,
      ...this.extraProps,
    };
    this.choice = selectMask(selectEle, option, this.type === SELECT_TYPE.MULTIPLE);
    const selectDOM = root.querySelector('.fil-choices');
    selectDOM.addEventListener('click', () => {
      this.choice.showDropdown();
    });

    const choiceInstance = this.choice;
    // multiple checkbox inputs
    if (this.type === SELECT_TYPE.MULTIPLE) {
      this.handlePlaceholder();
      selectDOM.addEventListener('choice', event => {
        const currentChoiceValue = event.detail.choice.value;
        if (event.detail.choice.selected) {
          setTimeout(() => {
            choiceInstance.removeActiveItemsByValue(currentChoiceValue);
            choiceInstance._triggerChange(currentChoiceValue);
          }, 10);
        }
      });
    }

    selectDOM.addEventListener('change', () => {
      this.value = choiceInstance.getValue(true);
    });

    if (this.className) {
      choiceInstance.containerOuter.element.classList.add(this.className);
    }
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'options' && typeof oldValue !== 'undefined') {
        this.choice.setChoices(this.options, 'value', 'label', true);
        this.setChoiceVale();
      }
    });

    if (changedProperties.has('disabled') || changedProperties.has('status')) {
      const selectDOM = this.shadowRoot.querySelector('.fil-choices');
      if (this.disabled || this.status === 'disabled') {
        this.choice.disable();
        selectDOM.removeEventListener('click', () => {
          this.choice.showDropdown();
        });
      } else {
        this.choice.enable();
        selectDOM.addEventListener('click', () => {
          this.choice.showDropdown();
        });
      }
    }

    if (changedProperties.has('value')) {
      this.setChoiceVale();
    }
  }

  // set choice value
  setChoiceVale() {
    const isMultiple = this.type === SELECT_TYPE.MULTIPLE;
    let selectedValue = this.value;
    if (isMultiple) {
      const { value } = this;
      if (Array.isArray(value)) {
        selectedValue = this.value;
      } else if (!this.value) {
        selectedValue = [];
      } else {
        selectedValue = this.value.split(',');
      }
    }

    if (isMultiple) {
      if (selectedValue.length > 0) {
        this.handleRemovableEle(selectedValue.length);
        this.handlePlaceholder('remove');
      } else {
        this.handleRemovableEle(selectedValue.length);
        this.handlePlaceholder();
      }
      this.choice.removeActiveItems();
    }
    this.choice.setChoiceByValue(selectedValue);
  }

  // add or remove placeholder node
  handlePlaceholder(operation) {
    const inner = this.shadowRoot.querySelector('.fil-choices__inner');
    if (!operation) {
      const placeholderNode = inner.querySelector('#placeholder');
      if (!placeholderNode) {
        const placeholderSpan = document.createElement('span');
        placeholderSpan.setAttribute('id', 'placeholder');
        placeholderSpan.textContent = this.placeholder ? this.placeholder : 'Select options';
        inner.appendChild(placeholderSpan);
      }
    } else {
      const placeholderNode = inner.querySelector('#placeholder');
      if (placeholderNode) {
        placeholderNode.remove();
      }
    }
  }

  handleRemovableEle(counts) {
    const multipleDemo = this.shadowRoot.querySelector('.fil-choices__inner');
    const input = multipleDemo.querySelector('input');
    // check whether have removable node
    const removableNode = multipleDemo.querySelector('#removedItem');

    if (counts === 0) {
      if (removableNode) {
        removableNode.remove();
      }
    } else if (!removableNode) {
      const actionWrapper = document.createElement('div');
      actionWrapper.setAttribute('id', 'removedItem');
      actionWrapper.setAttribute('class', 'fil-choices__list fil-choices__list--multiple');
      actionWrapper.innerHTML = `<div class="fil-choices__item fil-choices__item--removable"><span>${counts}</span> &nbsp;<button><fil-icon icon="close" size="8"></fil-icon></button></div><span>${this.selectedText}</span>`;
      const btn = actionWrapper.querySelector('button');
      if (!this.disabled) {
        btn.addEventListener('click', e => {
          this.choice.removeActiveItems();
          e.stopPropagation();
          actionWrapper.remove();
          this.handlePlaceholder();
          dispatchEvent(this, 'onchange change.gds.select', { value: [] });
        });
      }
      multipleDemo.insertBefore(actionWrapper, input);
    } else {
      const selectedCountNode = removableNode.querySelector('span');
      selectedCountNode.textContent = counts;
    }
  }

  renderOption(option) {
    if (this.type === SELECT_TYPE.SINGLE) {
      if (option.icon && this.icon) {
        return html`<fil-icon icon=${option.icon} size="24"></fil-icon>${option.label}`;
      }
    }
    return html`${option.label}`;
  }

  handleChange(e) {
    if (this.type === SELECT_TYPE.MULTIPLE) {
      const { options } = e.target;
      const values = [];
      for (let i = 0; i < options.length; i += 1) {
        values.push(options[i].value);
      }
      dispatchEvent(this, 'onchange change.gds.select', { value: values });
    } else {
      dispatchEvent(this, 'onchange change.gds.select', {
        value: e.target.value,
      });
    }
  }

  iconTemplate() {
    const className = 'fil-text-field__icon';
    const size = '20';
    if (this.status === 'valid') {
      return html`
        <fil-icon icon="custom-check-circle-1" size=${size} filled class=${className}></fil-icon>
      `;
    }
    if (this.status === 'invalid') {
      return html`
        <fil-icon icon="custom-alert-circle" size=${size} filled class=${className}></fil-icon>
      `;
    }
    return null;
  }

  renderElement() {
    const classes = {
      'fil-choice-wrapper': true,
      [`fil-choice-wrapper--small`]: this.size === 'small',
    };
    // const disabled = this.disabled || this.status === 'disabled';
    return html`
      <div class="${classMap(classes)}">
        <select
          ?multiple=${this.type === SELECT_TYPE.MULTIPLE}
          @change=${this.handleChange}
          .value=${ifDefined(this.value)}
          ?required=${this.required}
          aria-required=${!!this.required}
        >
          ${this.type === SELECT_TYPE.SINGLE && this.placeholder
            ? html` <option selected value="">${this.placeholder}</option> `
            : ''}
          ${this.options.map(
            option =>
              html`
                <option value=${option.value} ?disabled=${option.disabled}>
                  ${this.renderOption(option)}
                </option>
              `,
          )}
        </select>
        ${this.iconTemplate()}
      </div>
    `;
  }
}

customElements.define('fil-select', SelectComponent);
