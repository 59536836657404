/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { html, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { styleMap } from 'lit-html/directives/style-map';
import { dispatchEvent } from '@fil-global/gds-components/scripts/util/util';

import ifNonNull from '../globals/directives/isNotNull';
import baseStyles from '../base/base.styles';
import componentStyles from './fil-button.styles';
import '../fil-icon/fil-icon.component';

export class ButtonComponent extends LitElement {
  static get properties() {
    return {
      inversed: { type: Boolean },
      /**
       * @type {"primary"|"secondary"|"tertiary"|"textlink"|"white"}
       */
      kind: { type: String },
      label: { type: String },
      /**
       * @type {"small"}
       */
      size: { type: String },
      expand: { type: Boolean },
      disabled: { type: Boolean },
      icon: { type: String },
      /**
       * @type {"left"|"right"}
       */
      iconPosition: { String },
      iconSize: { type: Number },
      fill: { type: Boolean },
      url: { type: String },
      styles: { type: Object },
      extraProps: { type: Object },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
    this.kind = 'primary';
    this.iconPosition = 'right';
    this.expand = false;
    this.styles = {};
    this.extraProps = { target: '_self' };
    this.icon = null;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  iconTemplate() {
    if (this.icon === 'null' || this.icon === 'undefined' || !this.icon) return;
    return html` <span class="fil-button__icon${this.label ? `-${this.iconPosition}` : ''}"
      >${this.fill
        ? html`<fil-icon filled icon=${this.icon} size="${ifNonNull(this.iconSize)}"></fil-icon>`
        : html`<fil-icon icon=${this.icon} size="${ifNonNull(this.iconSize)}"></fil-icon>`}
    </span>`;
  }

  template() {
    return html`${this.icon ? this.iconTemplate() : ''}
    ${this.label ? html`<span class="fil-button--label">${this.label}</span>` : null}`;
  }

  handleClick(e) {
    if (this.extraProps.handleClick) {
      this.extraProps.handleClick();
    }
    dispatchEvent(this, 'onclick click.gds.button', { value: e.target.value });
  }

  render() {
    const classes = {
      'fil-button': true,
      [`fil-button--inversed`]: this.inversed,
      [`fil-button--${this.kind}`]: this.kind,
      [`fil-button--with-icon`]: this.icon,
      [`fil-button--${this.size}`]: this.size,
      'fil-button--expand': this.expand,
    };

    return this.url && !this.disabled
      ? html` <a
          class=${classMap(classes)}
          href=${[undefined, 'undefined', 'null', null].indexOf(this.url) > -1 ? '#' : this.url}
          ?disabled=${this.disabled}
          aria-label="${this.label || 'button'}"
          title="${this.label}"
          style=${styleMap(this.styles)}
          target=${ifNonNull(this.extraProps.target)}
          hreflang=${ifNonNull(this.extraProps.hreflang)}
          download=${ifNonNull(this.extraProps.download)}
          type=${ifNonNull(this.extraProps.type)}
          rel=${ifNonNull(this.extraProps.rel)}
          @click=${ifNonNull(this.extraProps.handleClick)}
        >
          <span class="fil-button--text">${this.template()}</span>
        </a>`
      : this.kind === 'text'
      ? html`<span
          class="fil-button--text"
          aria-label="${this.label || 'button'}"
          title="${this.label}"
          style=${styleMap(this.styles)}
          >${this.template()}</span
        >`
      : html`
          <button
            class="fil-button ${classMap(classes)}"
            ?disabled=${this.disabled}
            aria-label="${this.label || 'button'}"
            title="${this.label}"
            @click="${this.handleClick}"
            style=${styleMap(this.styles)}
          >
            ${this.kind === 'icon'
              ? html`<fil-icon filled icon="navigation-menu-horizontal"></fil-icon>`
              : this.template()}
          </button>
        `;
  }
}

customElements.define('fil-button', ButtonComponent);
