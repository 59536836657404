
const queryString = require('qs');
const { SearchSiteConfigHandler } = require('./utils.js');
import { TypeaheadHelper } from './common_helpers';
import {TopSearchFormHelper, SearchResultPageFormHelper} from './form_helpers';

class SearchCore {
  constructor() {
    let states = this.getGlobalStates();
    new TopSearchFormHelper(states);
    new SearchResultPageFormHelper(states);
  }

  getGlobalStates() {
    const typeahead = new TypeaheadHelper();
    const searchParams = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true
    });
    const fundsEnabled = $('.tabs-title[data-identity="fund"]').length > 0;
    const configHandlerInstance = new SearchSiteConfigHandler();
    configHandlerInstance.setConfigs($('.search-config').data('search-configs'));

    return { typeahead, searchParams, fundsEnabled, configHandlerInstance };
  }
}

$(() => {
  new SearchCore();
});
