import { html, LitElement } from 'lit-element';
import { styleMap } from 'lit-html/directives/style-map';
import { classMap } from 'lit-html/directives/class-map';
import { calculatePosition } from '@fil-global/gds-components/scripts/tooltip/index';

import '../fil-icon/fil-icon.component';
import '../fil-button/fil-button.component';
import baseStyles from '../base/base.styles';
import componentStyles from './fil-tooltip.styles';

class TooltipComponent extends LitElement {
  static get properties() {
    return {
      label: { type: String },
      /**
       * @type {"top"|"bottom"}
       */
      position: { type: String },
      /**
       * @type {"right"|"left"|"center"}
       */
      alignment: { type: String },
      message: { type: String },
      /**
       * @type {"hover"|"click"}
       */
      trigger: { type: String },
      size: { type: Number },
      fontSize: { type: Number },
      cta: { type: String },
      link: { type: String },
      _filled: { type: Boolean, attribute: false },
      _show: { type: Boolean, attribute: false },
      _style: { type: String, attribute: false },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
    this.position = 'top';
    this.alignment = 'center';
    this._filled = false;
    this._show = false;
    this.size = 16;
    this.trigger = 'click';
    this.fontSize = !this.fontSize && this.size;

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('click', this.handleClickOutside);
    window.addEventListener('keydown', this.handleKeydown);
  }

  disconnectedCallback() {
    window.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('keydown', this.handleKeydown);
    super.disconnectedCallback();
  }

  handleClickOutside(e) {
    const tooltipIcon = this.shadowRoot.querySelector('fil-icon');
    const path = e.path || (e.composedPath && e.composedPath());
    if (this._show && !path.includes(tooltipIcon)) {
      this.closeTooltip();
    }
  }

  handleKeydown(e) {
    if (e.keyCode === 27) {
      this.closeTooltip();
    }
  }

  handleKeyPress(e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
      this.handleClick(e);
    }
  }

  handleMouseover(e) {
    e.stopPropagation();
    this._filled = true;
  }

  handleMouseout(e) {
    e.stopPropagation();
    if (!this._show) {
      this._filled = false;
    }
  }

  handleClick(e) {
    if (!this._show) {
      this._filled = true;
      // calculate tooltip position
      const messageContainer = this.shadowRoot.querySelector('.fil-tooltip-msg');
      const calculateStyle = calculatePosition(e.target, messageContainer, {
        position: this.position,
        alignment: this.alignment,
        messageHeight: this.tipMessageHeight,
        messageWidth: this.tipMessageWidth,
      });
      this._style = `${calculateStyle} width: ${this.tipMessageWidth}px;`;
      setTimeout(() => {
        this._show = true;
      }, 200);
    } else {
      this.closeTooltip();
    }
  }

  closeTooltip() {
    this._filled = false;
    setTimeout(() => {
      this._show = false;
      this._style = 'display: none;';
    }, 10);
  }

  get messageTemplate() {
    const style = `${this._style};`;
    return html`
      <div class="fil-tooltip-msg" style="${style}" role="tooltip" aria-hidden="${!!this._show}">
        ${this.message}
        ${this.cta &&
        html`
          <fil-button
            class="fil-tooltip-msg__button"
            label="${this.cta}"
            kind="secondary"
            size="small"
            @click="${this.closeTooltip}"
          ></fil-button>
        `}
        ${this.link &&
        html`
          <fil-button
            class="fil-tooltip-msg__button"
            label="${this.link}"
            @click="${this.closeTooltip}"
            iconposition="none"
            kind="textlink"
            size="small"
            inversed="true"
          ></fil-button>
        `}
      </div>
    `;
  }

  render() {
    const classes = {
      'fil-tooltip-icon': true,
      'fil-tooltip-icon--margin-left': !!this.label,
    };
    return html`
      <div class="fil-tooltip-wrapper">
        <span style=${styleMap({ fontSize: `${this.fontSize}px` })} class="fil-tooltip-label">
          ${this.label}
        </span>
        <div class=${classMap(classes)}>
          <fil-icon
            icon="custom-info-circle"
            class="fil-tip"
            style=${styleMap({ lineHeight: `${this.size}px` })}
            @mouseover="${this.handleMouseover}"
            @mouseout="${this.handleMouseout}"
            @click="${this.handleClick}"
            @keypress="${this.handleKeyPress}"
            ?filled="${this._filled}"
            size="${this.size}"
            tabindex="0"
            aria-label="${this.message}"
          >
          </fil-icon>
          ${this.messageTemplate}
        </div>
      </div>
    `;
  }

  async firstUpdated() {
    const messageContainer = this.shadowRoot.querySelector('.fil-tooltip-msg');
    await this.updateComplete;
    this.tipMessageHeight = messageContainer.offsetHeight;
    this.tipMessageWidth = messageContainer.offsetWidth;
    this._style = 'display: none';
  }
}

customElements.define('fil-tooltip', TooltipComponent);
