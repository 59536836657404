import { LitElement, html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { dispatchEvent } from '@fil-global/gds-components/scripts/util/util';

import baseStyles from '../base/base.styles';
import '../fil-icon/fil-icon.component';
import componentStyles from './fil-page-level-message.styles';

/**
 * @slot - The message html content
 */

export class PageLevelMessageComponent extends LitElement {
  static get properties() {
    return {
      inversed: {
        type: Boolean,
      },
      /**
       * @type {"success" | "info" | "warning" | "error"}
       */
      status: { type: String },
      title: { type: String },
      closable: { type: Boolean },
      linkLabel: { type: String },
      linkUrl: { type: String },
      icon: { type: Boolean },
      show: { type: Boolean },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
    this.status = 'info';
    this.show = true;
  }

  get iconTemplate() {
    let icon = 'custom-info-circle';
    let label = 'Information';
    let color = 'blue-500';

    if (!this.status) {
      return '';
    } else {
      switch (this.status) {
        case 'error':
          icon = 'custom-alert-circle';
          label = 'error';
          color = 'brand-error-red';
          break;
        case 'info':
          icon = 'custom-info-circle';
          label = 'Information';
          color = 'blue-500';
          break;
        case 'success':
          icon = 'custom-check-circle-1';
          label = 'Success';
          color = 'green-600';
          break;
        case 'warning':
          icon = 'custom-alert-diamond';
          label = 'Warning';
          color = 'orange-400';
          break;
        default:
          console.warn('There is no status');
      }
      return html`<fil-icon
        icon=${icon}
        color=${color}
        filled
        label=${label}
      ></fil-icon>`;
    }
  }

  closeMessage() {
    this.show = false;
    dispatchEvent(this, 'onclose close.gds.page-level-message', {
      value: this.show,
    });
  }

  render() {
    const classes = {
      ['fil-page-level-message']: true,
      ['fil-page-level-message--inversed']: this.inversed,
      ['fil-page-level-message--' + this.status]: this.status,
    };

    const bodyClasses = {
      ['fil-page-level-message__body']: true,
      ['fil-page-level-message__body--no-icon']: !this.icon,
    };

    return html` ${this.show
      ? html`<div class=${classMap(classes)} role="alert">
          ${this.icon
            ? html`<div class="fil-page-level-message__icon">
                ${this.iconTemplate}
              </div>`
            : ''}
          <div class="${classMap(bodyClasses)}">
            ${this.closable
              ? html`<button
                  aria-label="Close"
                  class="fil-page-level-message__close"
                  @click="${this.closeMessage}"
                >
                  <fil-icon icon="close" size="16" label="Close"></fil-icon>
                </button>`
              : ``}
            ${this.title
              ? html`<p class="fil-page-level-message__title">
                  <strong>${this.title}</strong>
                </p>`
              : ''}
            <div class="fil-page-level-message__text"><slot></slot></div>
            ${this.linkUrl && this.linkLabel
              ? html`<p class="fil-page-level-message__link">
                <a href=${this.linkUrl} role="link" aria-label="${this.linkLabel}">${this.linkLabel}
              </p>`
              : ''}
          </div>
        </div> `
      : null}`;
  }
}

customElements.define('fil-page-level-message', PageLevelMessageComponent);
