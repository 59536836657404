export const DEFAULT_PAGE_FROM = 1;
export const DEFAULT_PER_PAGE = 10;

export const DEFAULT_SEARCH_URL = '/search/all';
export const AUTOSCROLL_SPEED = 1000;
export const DEFAULT_MIN_KEYWORD_LENGTH = 3;
export const KEYPRESS_RETURN = 13;
export const DEFAULT_FILTER_ALL = '__all__';
export const DE_FUND_FACTSHEET_URL = '/produkte-services/fonds-verschiedener-anbieter/fondsfinder/fondsportrait/?ISIN=';

export const FUND_FINDER_URL = 'https://cf.fww.de/fidelity/json-feeds/fondsfinder-mini-with-etfs.json';
export const DE_FUND_TYPE = 'de_fund';
export const UK_FUND_TYPE = 'ukfunds';
export const ARTICLE_TYPE = 'article';

export const DOCTYPE_BADGE_BGCOLORS = {
  'taiwanfunds': '#85E9E9',
  'article':     '#FFE57C',
  'otherdocs':   '#EEC9F2',
};
