import { html, LitElement } from 'lit-element';

import baseStyles from '../base/base.styles';
import componentStyles from './fil-hero-home.styles';

class HeroBlock extends LitElement {
  connectedCallback() {
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'rowgroup');
    }
    super.connectedCallback();
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  update(changedProperties) {
    const items = this.querySelectorAll('fil-hero-block-item');
    if (items.length === 2) {
      items.forEach((item) => item.setAttribute('horizontal', true));
    } else {
    }
    super.update(changedProperties);
  }

  render() {
    return html`<div class="fil-hero-block-wrapper"><slot></slot></div>`;
  }
}

customElements.define('fil-hero-home-block', HeroBlock);
