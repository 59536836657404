import {ISSArticlePDFExporter} from './article-export-as-pdf';
import { triggerSocialSharingTaggingEvent } from '_fil/fil-global-frontend-gds3/adobe_track';

class SocialSharingBase {
  constructor(el){
    this.$root = $(el);
    this.bindEvent();
  }

  bindEvent(){
  }
}

class SocialSharingPage extends SocialSharingBase {
  bindEvent() {
    const pageURL = encodeURIComponent(document.location.href);
    const pageTitle = encodeURIComponent(document.title);
    this.$root.find('.fil-icon-logo-facebook-1').on('click', ()=>{
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${pageURL}`);
      triggerSocialSharingTaggingEvent('facebook');
    });

    this.$root.find('.fil-icon-logo-line-1').on('click', ()=>{
      window.open(`https://social-plugins.line.me/lineit/share?url=${pageURL}`);
      triggerSocialSharingTaggingEvent('line');
    });

    this.$root.find('.fil-icon-messages-filled-1').on('click', ()=>{
      window.open(`mailto:?subject=${pageTitle}&body=${pageURL}`);
      triggerSocialSharingTaggingEvent('email');
    });
  }
}

class SocialSharingArticle extends SocialSharingBase {
  bindEvent() {
    const pageTitle = encodeURIComponent($('meta[property=\'og:title\']').attr('content'));
    const pageURL = encodeURIComponent(document.location.href);

    this.$root.find('.fil-icon-logo-linkedin').on('click', () => {
      triggerSocialSharingTaggingEvent('linkedin');
    });

    this.$root.find('.fil-icon-messages-filled').on('click', () => {
      triggerSocialSharingTaggingEvent('email');
    });

    this.$root.find('.fil-icon-logo-facebook').on('click', () => {
      triggerSocialSharingTaggingEvent('facebook');
    });

    this.$root.find('.fil-icon-logo-line').on('click', () => {
      window.open(`https://social-plugins.line.me/lineit/share?url=${pageURL}&text=${pageTitle}`);
      triggerSocialSharingTaggingEvent('line');
    });

    this.$root.find('.fil-icon-save-as-pdf').on('click', (event) => {
      new ISSArticlePDFExporter(event.currentTarget).start();
      triggerSocialSharingTaggingEvent('download');
    });
  }
}


$(function(){
  $('.social-sharing').each((i, el) => {
    if ($(el).hasClass('social-sharing--page')) {
      new SocialSharingPage(el);
    } else {
      new SocialSharingArticle(el);
    }
  });
});
