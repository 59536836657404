import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';

$(function(){
  const $rootElem = $('.global-cms-iss-article .article-main-content');
  const $observedElements = $rootElem.find('.social-sharing--vertical, .article-section-right-wrapper-inner');
  const anchorDOM = document.getElementById('article-floating-anchor');
  const footerContainerDOM = $('.fil-global-footer')[0];
  const stickyClassName = 'sticky-floating-section';
  let isTop = true;
  let isBottom = false;

  function detectFloatingAnchor(entry) {
    if (MediaQuery.atLeast('large')) {
      if (!entry.isIntersecting){
        if(!isBottom){
          $observedElements.addClass(stickyClassName);
        }
      } else {
        $observedElements.removeClass(stickyClassName);
      }
    }
  }

  function detectFooter(entry) {
    if (MediaQuery.atLeast('large')) {
      if (entry.isIntersecting){
        if(entry.intersectionRatio < 0.1){
          $observedElements.each(function(){
            const $elem = $(this);
            const elemTop = $elem.offset().top - $elem.parent().offset().top;
            $elem.css({
              'position': 'relative',
              'top': elemTop
            }).removeClass(stickyClassName);

            //if floating section has some overlapped part with the footer section, then animate the floating section up.
            const overlapped = $elem.offset().top + $elem.height() - $(footerContainerDOM).offset().top;
            if (overlapped >0) {
              $elem.css('top', elemTop - overlapped);
            }
          });
        }
      } else {
        if(!isTop){
          $observedElements.each(function(){
            const $elem = $(this);
            $elem.css({
              'position': '',
              'top': ''
            }).addClass(stickyClassName);
          });
        }
      }
    }
  }

  function detectPosition(entries){
    entries.forEach( entry => {
      switch(true) {
        case 'article-floating-anchor' === entry.target.id:
          isTop = entry.isIntersecting;
          break;
        case 'fil-global-footer' === entry.target.className:
          isBottom = entry.isIntersecting;
          break;
      }

      if(entry.intersectionRatio >= 0.1){
        //reset css attributes.
        $observedElements.css({
          'position': '',
          'top': ''
        }).removeClass(stickyClassName);
      }
    });
  }

  function handleFloating(entries){
    detectPosition(entries);
    entries.forEach( entry => {
      switch(true) {
        case 'article-floating-anchor' === entry.target.id:
          detectFloatingAnchor(entry);
          break;
        case 'fil-global-footer' === entry.target.className:
          detectFooter(entry);
          break;
      }
    });
  }

  function updateObservedElementsWidth() {
    $observedElements.each(function(){
      $(this).css('width', $(this).parent().width());
    });
  }

  if (anchorDOM && footerContainerDOM) {
    let observer = new IntersectionObserver(handleFloating, {});
    updateObservedElementsWidth();
    observer.observe(anchorDOM);
    observer.observe(footerContainerDOM);

    $(window).on('changed.zf.mediaquery', () => {
      if (MediaQuery.upTo('medium')) {
        $observedElements.removeClass(stickyClassName).attr('style', '');
      } else {
        updateObservedElementsWidth();
      }
    });
  }
});
