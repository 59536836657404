import { LitElement, html } from 'lit-element';
import uid from 'uid';
import { classMap } from 'lit-html/directives/class-map';
import { dispatchEvent } from '@fil-global/gds-components/scripts/util/util';

import baseStyles from '../base/base.styles';
import componentStyles from './fil-checkbox.styles';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';

class CheckboxComponent extends LitElement {
  static get properties() {
    return {
      inversed: { type: Boolean },
      label: { type: String },
      value: { type: String },
      checked: { type: Boolean },
      disabled: { type: Boolean },
      extraClass: { type: String },
      indeterminate: { type: Boolean },
      readonly: { type: Boolean },
      size: { type: String },
    };
  }

  constructor() {
    super();
    this.uid = uid();
    this.readonly = false;
    this.checked = false;
    this.size = null;
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  handleChange() {
    const input = this.shadowRoot.querySelector('input');
    this.checked = input.checked;
    this.value = input.value;
    dispatchEvent(
      this,
      'onchange change.gds.checkbox',
      {
        value: input.value,
        checked: input.checked,
        indeterminate: input.indeterminate,
      },
      { target: input }
    );

    if (this.indeterminate) {
      input.setAttribute('indeterminate', true);
    } else {
      input.removeAttribute('indeterminate');
    }
  }

  keyHandler(e) {
    if (e.keyCode === 32) {
      let input = e.target.parentNode.querySelector('input');
      input.checked = !input.checked;
      this.handleChange();
      e.preventDefault();
    }
  }

  firstUpdated() {
    const input = this.shadowRoot.querySelector('input');
    input.checked = this.checked;
    input.indeterminate = this.indeterminate;
  }

  getLabel(text) {
    return text ? html`${unsafeHTML(text)}` : '';
  }

  render() {
    const classes = {
      ['fil-checkbox']: true,
      ['fil-checkbox--small']: this.size === 'small' ? true : false,
      [`${this.extraClass}`]: !this.extraClass ? false : true,
      [`fil-checkbox--inversed`]: this.inversed,
    };
    return html`
      <div
        class="${classMap(classes)}"
        role="checkbox"
        aria-checked=${this.checked ? true : false}
        aria-readonly=${this.readonly ? true : false}
      >
        <input
          type="checkbox"
          class="fil-checkbox__field"
          id="${this.uid}"
          ?disabled=${this.disabled}
          ?checked=${this.checked}
          @change=${this.handleChange}
          aria-labelledby="label_${this.uid}"
          ?readonly=${this.readonly ? true : false}
          tabindex="-1"
        />
        <label
          class="fil-checkbox__label"
          for="${this.uid}"
          id="label_${this.uid}"
          tabindex="0"
          @keypress=${this.keyHandler}
          >${this.getLabel(this.label)}</label
        >
      </div>
    `;
  }
}

customElements.define('fil-checkbox', CheckboxComponent);
