import { html, LitElement, css } from 'lit-element';
import baseStyles from '../base/base.styles';
import { classMap } from 'lit-html/directives/class-map';
import componentStyles from './fil-loader.styles';
import { styleMap } from 'lit-html/directives/style-map';

const children_count = {
  ring: 4,
  roller: 8,
};
export class thisComponent extends LitElement {
  static get properties() {
    return {
      color: { type: String },
      kind: { type: String },
      ariaValueText: { type: String },
      size: { type: Object },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
    this.color = '';
    this.kind = 'ring';
    this.count = children_count[this.kind];
    this.ariaValueText =
      'Loading. Please wait. Do not refresh or navigate away from the page.';
  }
  render() {
    const _classes = {
      ['fil-loader-wrapper']: true,
      [`fil-loader--${this.kind}`]: this.kind ? true : false,
    };
    return html`<div
      role="progressbar"
      tabindex="0"
      aria-busy="true"
      aria-live="polite"
      aria-valuetext="${this.ariaValueText}"
      class="${classMap(_classes)}"
      style="${styleMap({ fontSize: this.size / 1.5 + 'px' })}"
    >
      ${[...Array(this.count).keys()].map(() => {
        return html`<div></div>`;
      })}
    </div>`;
  }
}

customElements.define('fil-loader', thisComponent);
