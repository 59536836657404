const Fuse = require('./fuse.min.js');
import {FUND_FINDER_URL, DE_FUND_TYPE} from './constants';

export class SearchEngine {
  constructor(searchHelper, options) {
    this.searchData = {};
    this.searchHelper = searchHelper;
    this.options = options || {};
  }

  /**
 * Call the DjangoCMS ElasticSearch API to query for CMS content
 * @param  {string} term Term to filter by
 */
  searchCMS(term) {
    const dfd = $.Deferred();
    const { url, pageCategory } = this.options;

    $.ajax({
      url: url,
      type: 'GET',
      data: {
        query_text: term,
        pagecategory: pageCategory,
        is_ajax: true
      },
      success: response => {
        dfd.resolve(response.data);
      },
      error: (_, textStatus, errorThrown) => {
        console.error(textStatus, errorThrown);
        dfd.resolve([]);
      }
    });
    return dfd.promise();
  }

  /**
  * Download all of the funds from the Fund Finder API and store the records
  * in the allFunds attribute.
  */
  downloadAllFunds() {
    const dfd = $.Deferred();

    if (this.allFunds.length) {
      dfd.resolve(this.allFunds);
    } else {
      $.ajax({
        dataType: 'jsonp',
        url: FUND_FINDER_URL,
        jsonpCallback: '_fondsSearchMinData',
        type: 'GET',
        success: response => {
          response.forEach(fund => (fund.type = DE_FUND_TYPE));
          this.allFunds = response;
        },
        error: (_, textStatus, errorThrown) => {
          console.error(textStatus, errorThrown);
          this.allFunds = [];
        },
        complete: () => {
          dfd.resolve(this.allFunds);
        }
      });
    }

    return dfd.promise();
  }

  /**
  * Search through the funds list. Uses Fuse.js for the client-side search.
  * http://fusejs.io/
  */
  searchDEFunds(term) {
    const dfd = $.Deferred();
    const { fundsEnabled } = this.options;

    if (fundsEnabled) {
      const options = {
        keys: ['FN', 'ISIN', 'WKN'],
        threshold: 0.25
      };
      $.when(this.downloadAllFunds()).done(allFunds => {
        const fuse = new Fuse(allFunds, options);
        dfd.resolve(fuse.search(term));
      });
    } else {
      dfd.resolve([]);
    }

    return dfd.promise();
  }

  search(term) {
    //send requests.
    const dfd = $.Deferred();
    if (term && this.lastSearch !== term) {
      this.lastSearch = term;
      this.page = 0;
      $.when(this.searchCMS(term), this.searchDEFunds(term))
        .then((cmsResults, fundResults) => {
          this.searchData = { cmsResults, fundResults };
          const isResultEmpty = cmsResults.length == 0 && fundResults.length == 0;
          dfd.resolve(true, isResultEmpty);
        }, (err) => {
          dfd.reject(err);
        });
    } else {
      dfd.resolve(false);
    }

    return dfd.promise();
  }
}
