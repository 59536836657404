/* global Foundation, countryCode */
import $ from 'jquery';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { Accordion } from 'foundation-sites/js/foundation.accordion';

$(function() {
  /**
   * Automatically open and close accordions based on screen size
   *
   * At smaller resolutions, collapse and enable the accordions. At large
   * desktop resolutions, expand and disable.
   */
  let isMobileDevice = !MediaQuery.atLeast('large');

  function responsiveFooterAccordions() {
    isMobileDevice = !MediaQuery.atLeast('large');
    const globalFooterAccordions = $('.fil-global-footer .accordion');
    let accordion = new Accordion(globalFooterAccordions);

    if (globalFooterAccordions.length) {
      const direction = isMobileDevice ? 'up' : 'down';
      globalFooterAccordions.attr('disabled', false);
      globalFooterAccordions.each(function() {
        const el = $(this);
        const pane = el.find('.accordion-content');
        el.foundation(direction, pane);
      });
      globalFooterAccordions.attr('disabled', !isMobileDevice);
      globalFooterAccordions.find('.show-for-large').attr('aria-hidden', isMobileDevice);
      globalFooterAccordions.find('.hide-for-large').attr('aria-hidden', !isMobileDevice);
    }
  }

  // Check footer accordions immediately and then every time the breakpoint changes
  responsiveFooterAccordions();
  $(window).on('changed.zf.mediaquery', responsiveFooterAccordions);

  /**
   * @description - Footer "scroll to top" function
   */
  $('.js-return-to-top').on('click', function() {
    // desired speed of scroll in px/second
    let speedDelta = 4000;
    // document height
    let docHeight = $(document).height();
    // calculated animation time height/speed * 1000 ( to convert to milliseconds for js)
    let timeDelta = (docHeight / speedDelta) * 1000;
    // The scrolling function
    $('html,body').animate(
      {
        scrollTop: 0
      },
      timeDelta
    );
  });

  //set float arrow button
  const $floatArrow = $('.return-to-top-button__container--float');
  if (countryCode === 'jp' && isMobileDevice && $floatArrow.length) {
    let lastScrollTop = $(window).scrollTop();
    $floatArrow.hide();

    $(window).scroll(
      Foundation.util.throttle(function() {
        const currentScrollTop = $(window).scrollTop();
        const scrollTopThreshold = window.innerHeight;

        if (currentScrollTop > lastScrollTop) {
          const scrollOffset = currentScrollTop - lastScrollTop;
          if (scrollOffset > 1) {
            $floatArrow.hide();
          }
        } else {
          if (currentScrollTop == 0) {
            $floatArrow.hide();
          } else {
            if (currentScrollTop > scrollTopThreshold) {
              $floatArrow.show();
            } else {
              $floatArrow.hide();
            }
          }
        }

        lastScrollTop = currentScrollTop;
      }, 100)
    );
  } else if (countryCode == 'hk') {
    $floatArrow.hide();

    $(window).scroll(
      Foundation.util.throttle(function() {
        /*
        * SCROLL_TOP_THRESHOLD - this constant value is taken from fidelity.com.hk, to keep the same effect, we need to use it here.
          when scrolling top of current window is more than 200, show the back-to-top button.

          when we scroll down and the footer section is visible, the back-to-top button will be hidden.
        */
        const SCROLL_TOP_THRESHOLD = 200;

        const scrollTopHeight = $(window).scrollTop();
        const documentHeight = $(document).height();
        const scrollTopThreshold = window.innerHeight;
        const footerMenuHeight = $('.fil-global-footer__bottom-menu').height();

        if (scrollTopHeight > SCROLL_TOP_THRESHOLD) {
          if (documentHeight - scrollTopHeight - scrollTopThreshold < footerMenuHeight) {
            //if true, means footer section is visible
            $floatArrow.hide();
          } else {
            $floatArrow.show();
          }
        } else {
          $floatArrow.hide();
        }
      })
    );
  }
});
