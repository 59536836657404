import { html, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { styleMap } from 'lit-html/directives/style-map';
import { getDeviceType, SMALL, MEDIUM } from '@fil-global/gds-components/scripts/util/util';

import { channel as flag, theme as styleTheme } from './util';
import baseStyles from '../base/base.styles';
import componentStyles from './fil-hero-home.styles';

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */
class HeroHomeComponent extends LitElement {
  static get properties() {
    return {
      /*
       * image aspect ratio: 21 : 9, for large and large up breakpoints
       * user should make sure the aspect ratio right
       */
      imageUrl: { type: String },
      /*
       * image aspect ratio: 16 : 9, for large and large up breakpoints
       * user should make sure the aspect ratio right
       */
      smallImageUrl: { type: String },
      // normal | dark
      theme: { type: String },
      inverse: { type: Boolean },
      /** defined in util file: {
       * CARD: 'card',
       * CARD_SMALL: 'smallCard',
       * INTRO: 'intro',
       * BLOCK: 'block',
      }; */
      channel: { type: String },
    };
  }

  static get styles() {
    return [baseStyles, componentStyles];
  }

  constructor() {
    super();
    this.inverse = false;
    this._handleResize = this.handleWindowResize.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this._handleResize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this._handleResize);
  }

  handleWindowResize() {
    setTimeout(() => {
      const tempVar = this._size;
      this._size = this.isLarge;
      if (this._size !== tempVar) {
        this.requestUpdate();
      }
    }, 50);
  }

  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["isLarge"] }] */
  get isLarge() {
    const breakpoint = getDeviceType();
    const isLarge = breakpoint !== SMALL && breakpoint !== MEDIUM;
    return isLarge;
  }

  renderSlot() {
    const { channel } = this;
    const imageHeightCls = {
      cell: true,
      'image-height': true,
      'image-height--small': this.channel === flag.INTRO,
      'image-height--medium': this.channel === flag.CARD,
      'image-height--position': true,
      'image-height--auto': true,
    };
    if (channel === flag.CARD) {
      return html`<div class=${classMap(imageHeightCls)}>
        <slot></slot>
      </div>`;
    }
    if (channel === flag.INTRO) {
      return html`<div class="cell small-12 large-10"><slot></slot></div>`;
    }
    return html` <div class=${classMap(imageHeightCls)}>
        <slot name="card"></slot>
      </div>
      <div class="cell"><slot name="block"></slot></div>`;
  }

  render() {
    const size = !this._size ? this.isLarge : this._size;
    const bgImageUrl = size ? this.imageUrl : this.smallImageUrl;
    const styles = {
      backgroundImage: `url(${!bgImageUrl ? this.imageUrl : bgImageUrl})`,
    };
    const bgColorClass = {
      'fil-hero': true,
      'fil-hero--dark': !(!this.theme || this.theme === styleTheme.NORMAL),
      'fil-hero--inverse': !!this.inverse,
    };
    const heroImageKindCls = {
      'fil-hero-image-wrapper': true,
      'fil-hero-image-wrapper--small': this.channel === flag.INTRO,
      'fil-hero-image-wrapper--medium': this.channel === flag.CARD,
    };
    const imageHeightCls = {
      'image-height': true,
      'image-height--small': this.channel === flag.INTRO,
      'image-height--medium': this.channel === flag.CARD,
    };

    const parallelogramWrapperCls = {
      cell: true,
      ...imageHeightCls,
      'small-card-height': this.channel === flag.CARD_SMALL,
    };

    // set background color if there is no image
    const bgCls = {
      'fil-hero__image': true,
      'fil-hero__image--grey': !this.imageUrl,
    };

    return html`
      <div class="fil-hero-wrapper">
        <section class=${classMap(bgColorClass)}>
          <div class=${classMap(heroImageKindCls)}>
            <div class=${classMap(bgCls)} style=${styleMap(styles)}>
              <div class="fil-parallelogram-container--right">
                <div class="fil-parallelogram">&nbsp;</div>
                <div class="fil-parallelogram-last">&nbsp;</div>
              </div>
            </div>
          </div>
          <div class="grid-container">
            <div class="grid-x position-relative">
              <div class=${classMap(parallelogramWrapperCls)}>
                <div class="fil-parallelogram-container">
                  <div class="fil-parallelogram">&nbsp;</div>
                </div>
              </div>
              ${this.renderSlot()}
            </div>
          </div>
        </section>
      </div>
    `;
  }
}
customElements.define('fil-hero-home', HeroHomeComponent);
