const Bowser = require('bowser/bundled');
import $ from 'jquery';

$(document).ready(function() {
  function checkBrowserCompatibility() {
    if (window.BROWSER_MESSAGE_ENABLED || window.OS_MESSAGE_ENABLE) {
      const browser = Bowser.getParser(window.navigator.userAgent);
      const clientOs = browser.getOS();
      let isValidOs = true;
      if (window.OS_MESSAGE_ENABLE && clientOs.name == 'Windows') {
        let versions = window.OS_VERSIONS.OS.Windows;
        const isOutdatedOs = !(versions.indexOf(clientOs.version) > -1);
        isValidOs = typeof versions === 'undefined' ? true : isOutdatedOs;
      }

      let isValidBrowser = browser.satisfies({
        chrome: `>=${window.CHROME_MIN_VERSION}`,
        firefox: `>=${window.FIREFOX_MIN_VERSION}`,
        safari: `>=${window.SAFARI_MIN_VERSION}`,
        ie: `>=${window.IE_MIN_VERSION}`,
        edge: `>=${window.EDGE_MIN_VERSION}`
      });

      // if browser not checked in satisfies method then treating as valid
      isValidBrowser = typeof isValidBrowser === 'undefined' ? true : isValidBrowser;
      if (!isValidOs) {
        $('#browserMessage').hide();
        $('#osMessage').show();
      } else if (!isValidBrowser) {
        $('#osMessage').hide();
        $('#browserMessage').show();
      }

      const showMessage = !isValidOs || !isValidBrowser;
      $('#browserWarningContainer').toggle(showMessage);

      $('#browserWarningContainer a.fil-icon-cross-light').on('click touch', function() {
        $('#browserWarningContainer').hide();
      });
    }
  }
  checkBrowserCompatibility();
});
